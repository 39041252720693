/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Card from 'common/card';
import Input from 'common/input';
import PlainButton from 'common/plain-button';

function AddCategory(props) {
  const {
    adding,
    error,
    name,
    onAdd,
    onCancel,
    onNameChanged,
    onSubmit,
    submitting,
  } = props;

  return (
    <div className="bg-white shadow-lg rounded-lg">
      {!adding && (
        <div className="px-6 py-4">
          <PlainButton onClick={onAdd}>+ Add new category</PlainButton>
        </div>
      )}

      {adding && (
        <>
          <Card.Title>Add category</Card.Title>

          <form
            className="px-6 pt-4 pb-6"
            disabled={submitting}
            onSubmit={onSubmit}
          >
            <Input
              className="mb-6"
              label="Category name"
              disabled={submitting}
              error={error}
              onChange={onNameChanged}
              value={name}
            />

            <div className="flex items-center">
              <Button
                loading={submitting}
                type="submit"
              >
                Add
              </Button>
              <PlainButton
                className="ml-4"
                color="gray"
                disabled={submitting}
                onClick={onCancel}
                type="button"
              >
                Cancel
              </PlainButton>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default AddCategory;
