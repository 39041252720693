/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Field from 'common/input/field';
import Icon from 'common/icon';

function User(props) {
  const {
    onBack,
    onForgotPassword,
    onPasswordChanged,
    onSubmit,
    password,
    submitting,
    validationErrors,
  } = props;

  return (
    <>
      <button
        className="relative focus:outline-none p-0 font-semibold text-sm disabled:cursor-not-allowed text-gray-600 focus:text-blue-600 hover:text-blue-600 disabled:text-gray-600 uppercase block mb-6"
        onClick={onBack}
        type="button"
      >
        <Icon
          className="mr-2"
          icon="left-arrow"
        />
        <span>Back</span>
      </button>

      <form onSubmit={onSubmit}>
        <Field
          className="py-5 px-6 text-lg text-gray-800"
          error={validationErrors.password}
          onChange={onPasswordChanged}
          placeholder="Password"
          type="password"
          value={password}
        />
        <div className="text-right">
          <button
            className="inline-block mt-1 text-gray-600 text-xs text-gray-600 font-semibold"
            onClick={onForgotPassword}
            type="button"
          >
            Forgot password?
          </button>
        </div>

        <Button
          className="mt-4 py-3 w-full"
          loading={submitting}
          type="submit"
        >
          Login
        </Button>
      </form>
    </>
  );
}

export default User;
