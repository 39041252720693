/* ------ Module imports ------ */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/* ------ Helpers ------ */
import api from 'helpers/api';

/* ------ View import ------ */
import ChatPlugin from './chat-plugin.view';

function ChatPluginContainer() {
  const { id } = useParams();

  const [view, setView] = useState('loading');
  const [chatPlugin, setChatPlugin] = useState(null);

  async function fetchChatPlugin() {
    const { data } = (await api.get(`/chat_plugin/${id}?expand[]=domains&expand[]=page&expand[]=category_set&expand[]=hours`)).data;
    return data;
  }

  async function fetchData() {
    setView('loading');

    let loadedPlugin = null;
    try {
      [loadedPlugin] = await Promise.all([
        fetchChatPlugin(),
      ]);
    } catch (e) {
      // Silently ignore - `loadedPlugin` will be null so will show an error
    }

    if (loadedPlugin) {
      setChatPlugin(loadedPlugin);
      setView('main');
    } else {
      setView('error');
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function onCategorySetUpdated(categorySet) {
    const updatedChatPlugin = { ...chatPlugin };
    updatedChatPlugin.category_set = categorySet;
    setChatPlugin(updatedChatPlugin);
  }

  function onColorsUpdated(foregroundColor, backgroundColor) {
    const updatedChatPlugin = { ...chatPlugin };
    updatedChatPlugin.foreground_color = foregroundColor;
    updatedChatPlugin.background_color = backgroundColor;
    setChatPlugin(updatedChatPlugin);
  }

  function onProfilePictureUpdated(profilePictureUrl) {
    setChatPlugin({ ...chatPlugin, profile_picture: profilePictureUrl });
  }

  return (
    <ChatPlugin
      chatPlugin={chatPlugin}
      onCategorySetUpdated={onCategorySetUpdated}
      onColorsUpdated={onColorsUpdated}
      onChatPluginUpdated={setChatPlugin}
      onDomainsUpdated={domains => setChatPlugin({ ...chatPlugin, domains })}
      onProfilePictureUpdated={onProfilePictureUpdated}
      view={view}
    />
  );
}

export default ChatPluginContainer;
