/* ------ Module imports ------ */
import React, { useState } from 'react';
import Select, { components } from 'react-select';

/* ------ Helpers ------ */
import history from 'helpers/history';

/* ------ Common imports ------ */
import IconButton from 'common/icon-button';
import PlainButton from 'common/plain-button';

function SingleValue({ children, ...props }) {
  const { data } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.SingleValue {...props}>
      <div className="flex items-center">
        <div className="w-6 h-6 mr-2 rounded bg-gray-500 bg-cover bg-center" style={{ backgroundImage: `url(${data.profile_picture})` }} />
        <span className="text-sm text-gray-800 font-medium">{data.label}</span>
      </div>
    </components.SingleValue>
  );
}

function Option({ children, ...props }) {
  const { data } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      <div className="flex items-center">
        <div className="w-6 h-6 mr-2 rounded bg-gray-500 bg-cover bg-center" style={{ backgroundImage: `url(${data.profile_picture})` }} />
        <span className="text-sm text-gray-800 font-medium">{data.label}</span>
      </div>
    </components.Option>
  );
}

function NoOptionsMessage({ children, ...props }) {
  const { selectProps } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.NoOptionsMessage {...props}>
      <div className="text-center py-4">
        {!selectProps.error && (
          <>
            <p className="mb-2">No user found.</p>
            <PlainButton onClick={() => history.push('/users?expand=invite')}>Want to invite them?</PlainButton>
          </>
        )}
        {selectProps.error && (
          <p className="text-yellow-500">Could not load users. Please try again.</p>
        )}
      </div>
    </components.NoOptionsMessage>
  );
}

function UserSelector(props) {
  const {
    data,
    error,
    isMulti,
    loading,
    onCancel,
    onChange,
    onConfirm,
    onFocus,
    submitting,
    value,
  } = props;

  const [menuOpen, setMenuOpen] = useState(true);

  let options = [];
  if (data) {
    options = data.map(user => {
      const userObject = {
        value: user.id,
        profile_picture: user.profile_picture,
        object: user.object,
      };

      if (user.object === 'invite') {
        userObject.label = `${user.email} (pending)`;
      } else {
        userObject.label = `${user.first_name} ${user.last_name}`;
      }

      return userObject;
    });
  }

  return (
    <div className="flex">
      <div className="flex-1">
        <Select
          autoFocus
          classNamePrefix="select"
          components={{ SingleValue, Option, NoOptionsMessage }}
          error={error}
          isDisabled={submitting}
          isLoading={loading}
          isMulti={isMulti}
          menuIsOpen={menuOpen}
          options={options}
          onChange={onChange}
          onFocus={onFocus}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          placeholder="Choose a user"
          value={value}
        />
      </div>
      <IconButton
        color="green"
        icon="check"
        loading={submitting}
        onClick={onConfirm}
      />
      <IconButton
        color="gray"
        disabled={submitting}
        icon="cross"
        onClick={onCancel}
      />
    </div>
  );
}

export default UserSelector;
