/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Card from 'common/card';
import Input from 'common/input';

function CreateBusiness(props) {
  const {
    error,
    name,
    onNameChanged,
    onSubmit,
    submitting,
  } = props;

  return (
    <>
      <h1 className="font-heading text-2xl font-medium text-gray-800">Create a new business</h1>
      <Card className="mt-10 p-6 pt-4">
        <Input
          error={error}
          label="Business name"
          onChange={onNameChanged}
          value={name}
        />
      </Card>

      <div className="mt-10 text-right">
        <Button
          loading={submitting}
          onClick={onSubmit}
        >
          Create
        </Button>
      </div>
    </>
  );
}

export default CreateBusiness;
