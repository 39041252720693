/* ------ Module imports ------ */
import React from 'react';

function Toast(props) {
  const { children } = props;

  return (
    <div className="fixed left-0 bottom-0 pb-6 w-full text-center z-50 toast">
      <div className="inline-block py-2 px-4 text-sm bg-gray-800 text-white rounded-lg shadow-md">
        {children}
      </div>
    </div>
  );
}

export default Toast;
