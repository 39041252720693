/* ------ Module imports ------ */
import React from 'react';

function Icon(props) {
  const {
    className,
    fontSize,
    icon,
  } = props;

  const style = {};
  if (fontSize) {
    style.fontSize = fontSize;
  }

  return (
    <i
      className={`ralli-icon-${icon} ${className}`}
      style={style}
    />
  );
}

export default Icon;
