/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Card from 'common/card';
import Radio from 'common/radio';

function PopupSetting(props) {
  const {
    chatPlugin,
    onPopupSettingChanged,
    onSave,
    popupSetting,
    submitting,
  } = props;

  return (
    <Card className="mt-10">
      <p className="px-6 py-4 border-b border-gray-300 font-medium text-gray-800 text">Popup behaviour</p>

      <div className="p-6">
        <div className="flex items-center">
          <Radio
            onSelect={() => onPopupSettingChanged('always')}
            selected={popupSetting === 'always'}
          />
          <p className="ml-2 text-gray-800 text-sm">Automatically pop up on every webpage</p>
        </div>
        <div className="mt-2 flex items-center">
          <Radio
            onSelect={() => onPopupSettingChanged('until_dismissed')}
            selected={popupSetting === 'until_dismissed'}
          />
          <p className="ml-2 text-gray-800 text-sm">Pop up until it is dismissed by the visitor</p>
          <span className="ml-2 bg-yellow-200 text-yellow-700 text-xs font-semibold p-1 rounded">Recommended option</span>
        </div>
        <div className="mt-2 flex items-center">
          <Radio
            onSelect={() => onPopupSettingChanged('never')}
            selected={popupSetting === 'never'}
          />
          <p className="ml-2 text-gray-800 text-sm">Never pop up automatically</p>
        </div>
      </div>

      {popupSetting !== chatPlugin.popup_setting && (
        <div className="px-6 py-4 flex justify-end border-t border-gray-300">
          <Button
            loading={submitting}
            onClick={onSave}
          >
            Save changes
          </Button>
        </div>
      )}
    </Card>
  );
}

export default PopupSetting;
