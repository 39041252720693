/* ------ Module imports ------ */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

/* ------ Common imports ------ */
import Loading from 'common/loading';

/* ------ Helpers imports ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View imports ------ */
import Main from './main';
import Reorder from './reorder';

function CategorySetContainer() {
  const { id } = useParams();

  const [categorySet, setCategorySet] = useState(null);
  const [categories, setCategories] = useState(null);
  const [reorderView, setReorderView] = useState(false);
  const [view, setView] = useState('loading');

  async function fetchCategorySet() {
    const { data } = (await api.get(`/category_set/${id}?expand[]=in_use_by`)).data;
    return data;
  }

  async function fetchCategories() {
    const { data } = (await api.get(`/category?category_set=${id}&expand[]=users&expand[]=invites`)).data;
    return data;
  }

  async function fetchData() {
    let loadedCategorySet = null;
    let loadedCategories = null;

    try {
      [loadedCategorySet, loadedCategories] = await Promise.all([
        fetchCategorySet(),
        fetchCategories(),
      ]);
    } catch (e) {
      // Silently ignore - will be null so will show error
    }

    if (loadedCategorySet && loadedCategories) {
      setCategorySet(loadedCategorySet);
      setCategories(loadedCategories);
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchData();
  });

  function onCategoryAdded(category) {
    const updatedCategories = categories.concat(category);
    setCategories(updatedCategories);
  }

  function onCategoryUpdated(updatedCategory) {
    const updatedCategories = categories.map(category => {
      if (category.id === updatedCategory.id) {
        return updatedCategory;
      }
      return category;
    });

    setCategories(updatedCategories);
  }

  function onCategoryDeleted(category) {
    function deleteCategory(cats) {
      const updatedCategories = cats.filter(cat => {
        if (cat.id === category.id) {
          return false;
        }

        if (cat.children) {
          cat.children = deleteCategory(cat.children);
        }

        return true;
      });

      return updatedCategories;
    }

    const updatedCategories = deleteCategory(categories);
    setCategories(updatedCategories);
  }

  function onRenamed(name) {
    const updatedCategorySet = categorySet;
    updatedCategorySet.name = name;

    setCategorySet(updatedCategorySet);
  }

  function renderError() {
    // TODO.
    return null;
  }

  if (view === 'loading') {
    return <Loading />;
  }

  if (view === 'error') {
    return renderError();
  }

  if (reorderView) {
    return (
      <Reorder
        categorySet={categorySet}
        categories={categories}
        onCategoriesUpdated={setCategories}
        onDone={() => setReorderView(false)}
      />
    );
  }

  return (
    <Main
      categorySet={categorySet}
      categories={categories}
      onCategoryAdded={onCategoryAdded}
      onCategoryDeleted={onCategoryDeleted}
      onCategoryUpdated={onCategoryUpdated}
      onRenamed={onRenamed}
      onReorder={() => setReorderView(true)}
    />
  );
}

export default CategorySetContainer;
