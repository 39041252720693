/* ------ Module imports ------ */
import React from 'react';

/* ------ Local components ------ */
import Delete from './delete';
import Options from './options';

function Webhook(props) {
  const {
    deleting,
    onCancelDeleting,
    onDeleted,
    onStartDeleting,
    webhook,
  } = props;

  function renderEvent(name, i) {
    return (
      <div
        className="mr-2 px-2 py-1 text-gray-800 text-xs rounded bg-gray-200"
        key={i}
      >
        {name}
      </div>
    );
  }

  return (
    <div className="border-b last:border-b-0 border-gray-200">
      <div className="px-6 py-4">
        <div>
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-800 font-medium">{webhook.url}</p>
              <div className="flex mt-2">
                {webhook.events.map(renderEvent)}
              </div>
            </div>

            {!deleting && <Options onDelete={onStartDeleting} />}
          </div>
        </div>
      </div>

      {deleting && (
        <Delete
          onCancel={onCancelDeleting}
          onDeleted={onDeleted}
          webhook={webhook}
        />
      )}
    </div>
  );
}

export default Webhook;
