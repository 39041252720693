/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Domain from './domain.view';

function DomainContainer(props) {
  const {
    domain,
    onDomainDeleted,
  } = props;

  const [deleting, setDeleting] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  async function onConfirmDelete() {
    setSubmitting(true);

    let success = false;
    try {
      await api.remove(`/chat_plugin_domain/${domain.id}`);
      success = true;
    } catch (e) {
      // Silently ignore - `success` will be false so will show an error below
    }

    if (success) {
      toast('Domain successfully deleted');
      onDomainDeleted(domain);
    } else {
      toast('Something went wrong. Please try again.');
    }
  }

  return (
    <Domain
      deleting={deleting}
      domain={domain}
      onCancelDelete={() => setDeleting(false)}
      onConfirmDelete={onConfirmDelete}
      onDelete={() => setDeleting(true)}
      submitting={submitting}
    />
  );
}

export default DomainContainer;
