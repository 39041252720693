/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Card from 'common/card';
import Radio from 'common/radio';

function EmailRequiredSetting(props) {
  const {
    chatPlugin,
    emailSetting,
    onEmailSettingChanged,
    onSave,
    submitting,
  } = props;

  return (
    <Card className="mt-10">
      <p className="px-6 py-4 border-b border-gray-300 font-medium text-gray-800 text">Starting form</p>

      <div className="p-6">
        <p className="text-gray-800 text-sm font-medium mb-3">Do you want the email field on new chats to be required?</p>

        <div className="flex items-center">
          <Radio
            onSelect={() => onEmailSettingChanged(false)}
            selected={!emailSetting}
          />
          <p className="ml-2 text-gray-800 text-sm">Not required</p>
        </div>
        <div className="mt-2 flex items-center">
          <Radio
            onSelect={() => onEmailSettingChanged(true)}
            selected={emailSetting}
          />
          <p className="ml-2 text-gray-800 text-sm">Required (note that this may increase customer dropoff)</p>
        </div>
      </div>

      {emailSetting !== chatPlugin.email_required && (
        <div className="px-6 py-4 flex justify-end border-t border-gray-300">
          <Button
            loading={submitting}
            onClick={onSave}
          >
            Save changes
          </Button>
        </div>
      )}
    </Card>
  );
}

export default EmailRequiredSetting;
