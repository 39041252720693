/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import ChangeColors from './change-colors.view';

function ChangeColorsContainer(props) {
  const {
    chatPlugin,
    onColorsUpdated,
  } = props;

  const [newBGColor, setNewBGColor] = useState(chatPlugin.background_color);
  const [newFGColor, setNewFGColor] = useState(chatPlugin.foreground_color);

  const [submitting, setSubmitting] = useState(null);

  function onCancel() {
    setNewBGColor(chatPlugin.background_color);
    setNewFGColor(chatPlugin.foreground_color);
  }

  async function onSave() {
    setSubmitting(true);

    const postData = {
      background_color: newBGColor,
      foreground_color: newFGColor,
    };

    let success = false;
    try {
      await api.patch(`/chat_plugin/${chatPlugin.id}`, postData);
      success = true;
    } catch (e) {
      // Silently ignore - `success` will be false so will show an error
    }

    setSubmitting(false);

    if (success) {
      onColorsUpdated(newFGColor, newBGColor);
    } else {
      toast('Could not update colors - something went wrong');
    }
  }

  const showActions = newBGColor !== chatPlugin.background_color
    || newFGColor !== chatPlugin.foreground_color;

  return (
    <ChangeColors
      backgroundColor={newBGColor}
      foregroundColor={newFGColor}
      greeting={chatPlugin.greeting}
      name={chatPlugin.name}
      onBackgroundColorChanged={setNewBGColor}
      onCancel={onCancel}
      onForegroundColorChanged={setNewFGColor}
      onSave={onSave}
      showActions={showActions}
      submitting={submitting}
    />
  );
}

export default ChangeColorsContainer;
