/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import PlainButton from 'common/plain-button';

function Delete(props) {
  const {
    onCancel,
    onDelete,
    submitting,
  } = props;

  return (
    <div className="pl-6">
      <div className="py-4 border-t border-gray-200">
        <p className="text-sm font-medium text-gray-800">Are you sure you want to delete this webhook?</p>
        <p className="text-sm text-gray-800 mb-4">Note that this will mean the url for this webhook will stop receiving events.</p>

        <Button
          destructive
          loading={submitting}
          onClick={onDelete}
        >
          Delete
        </Button>
        <PlainButton
          className="ml-4"
          disabled={submitting}
          onClick={onCancel}
        >
          Cancel
        </PlainButton>
      </div>
    </div>
  );
}

export default Delete;
