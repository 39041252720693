/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import RenameCategorySet from './rename-category-set.view';

function RenameCategorySetContainer(props) {
  const {
    categorySet,
    onCancel,
    onNameChanged,
  } = props;

  const [name, setName] = useState(categorySet.name);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  async function onSubmit(ev) {
    ev.preventDefault();

    if (!name || !name.trim()) {
      setError(true);
      return;
    }

    setSubmitting(true);
    setError(false);

    let updatedCategorySet = null;
    try {
      const { data } = (await api.patch(`/category_set/${categorySet.id}`, { name })).data;
      updatedCategorySet = data;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (updatedCategorySet) {
      onNameChanged(updatedCategorySet.name);
    } else {
      toast('Could not rename. Something went wrong.');
    }
  }

  return (
    <RenameCategorySet
      error={error}
      name={name}
      onCancel={onCancel}
      onNameChanged={setName}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default RenameCategorySetContainer;
