/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';
import Loading from 'common/loading';

function Accounts(props) {
  const {
    accounts,
    error,
    loading,
    onBack,
    onSelectAccount,
  } = props;

  function renderError() {
    return (
      <>
        <button
          className="relative focus:outline-none p-0 font-semibold text-sm disabled:cursor-not-allowed text-gray-600 focus:text-blue-600 hover:text-blue-600 disabled:text-gray-600 uppercase block mb-10"
          onClick={onBack}
          type="button"
        >
          <Icon
            className="mr-2"
            icon="left-arrow"
          />
          <span>Back</span>
        </button>

        <div className="bg-yellow-300 text-yellow-500 flex justify-center items-center rounded-full w-10 h-10">
          <Icon icon="warning" />
        </div>
        <p className="mt-6 font-medium text-gray-800 mb-2 text-xl">Could not load your accounts</p>
        <p className="text-gray-800 text-sm">Something went wrong loading your accounts. Please try again or contact us if you keep having this problem.</p>
      </>
    );
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return renderError();
  }

  function renderAccount(account) {
    return (
      <button
        className="bg-white focus:outline-none font-semibold text-gray-800 w-full px-6 py-5 hover:border-blue-500 rounded-lg border border-gray-200 mt-4 flex justify-between items-center"
        key={account.id}
        onClick={() => onSelectAccount(account)}
        type="button"
      >
        <p>{account.name}</p>

        <Icon
          className="text-gray-600"
          icon="right-chevron"
        />
      </button>
    );
  }

  return (
    <div>
      <button
        className="relative focus:outline-none p-0 font-semibold text-sm disabled:cursor-not-allowed text-gray-600 focus:text-blue-600 hover:text-blue-600 disabled:text-gray-600 uppercase block mb-6"
        onClick={onBack}
        type="button"
      >
        <Icon
          className="mr-2"
          icon="left-arrow"
        />
        <span>Back</span>
      </button>

      {accounts.map(renderAccount)}
      {accounts.length === 0 && (
        <>
          <p className="text-yellow-500 font-semibold text-sm mb-10">You aren&apos;t signed up to any Ralli Connect accounts.</p>
        </>
      )}
    </div>
  );
}

export default Accounts;
