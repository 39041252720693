/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';
import LoadingSpinner from 'common/loading-spinner';

function IconButton(props) {
  const {
    color,
    disabled,
    icon,
    loading,
    onClick,
    type,
  } = props;

  function buildClassName() {
    let className = 'w-10 ml-2 rounded-lg text-sm flex items-center justify-center disabled:cursor-not-allowed';

    switch (color) {
      case 'green':
        className += ' bg-green-500 hover:bg-green-600 text-white disabled:opacity-50';
        break;
      case 'gray':
        className += ' bg-gray-200 hover:bg-gray-300 text-gray-600 disabled:opacity-50';
        break;
      default:
        // Do nothing
    }

    return className;
  }

  if (type === 'submit') {
    return (
      <button
        className={buildClassName()}
        disabled={loading || disabled}
        type="submit"
      >
        {loading && <LoadingSpinner small white />}
        {!loading && <Icon icon={icon} />}
      </button>
    );
  }

  return (
    <button
      className={buildClassName()}
      disabled={loading || disabled}
      onClick={onClick}
      type="button"
    >
      {loading && <LoadingSpinner small white />}
      {!loading && <Icon icon={icon} />}
    </button>
  );
}

export default IconButton;
