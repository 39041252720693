/* ------ Module imports ------ */
import React from 'react';

function Radio(props) {
  const {
    onSelect,
    selected,
  } = props;

  let className = 'w-4 h-4 border-2 border-gray-600 rounded-full flex items-center justify-center hover:cursor-pointer focus:outline-none';
  if (selected) {
    className += ' border-blue-500';
  } else {
    className += ' border-gray-600';
  }

  function onKeyPress(e) {
    if (e.keyCode === 13) {
      onSelect();
    }
  }

  return (
    <div
      aria-checked={selected}
      className={className}
      onClick={onSelect}
      onKeyPress={onKeyPress}
      role="radio"
      tabIndex="0"
    >
      {selected && <div className="w-2 h-2 rounded-full bg-blue-500" />}
    </div>
  );
}

export default Radio;
