/* ------ Module imports ------ */
import React, { useContext } from 'react';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Helpers ------ */
import userUtil from 'helpers/user';

/* ------ Local components ------ */
import ApiKeys from './api-keys';
import Details from './details';
import Users from './users';
import Webhooks from './webhooks';

function Settings() {
  const { user } = useContext(PlatformContext);

  async function onAccountUpdated() {
    userUtil.refreshUser();
  }

  return (
    <>
      <h1 className="font-heading text-2xl font-medium text-gray-800">Account settings</h1>

      <Details
        account={user.connect_account}
        onAccountUpdated={onAccountUpdated}
      />

      <ApiKeys />
      <Webhooks />

      <Users />
    </>
  );
}

export default Settings;
