/* ------ Module imports ------ */
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

/* ------ Helpers ------ */
import api from 'helpers/api';
import auth from 'helpers/auth';
import toast from 'helpers/toast';
import useDidMount from 'helpers/hooks/use-did-mount';
import userUtil from 'helpers/user';

/* ------ View import ------ */
import AcceptInvite from './accept-invite.view';

const INITIAL_DATA = {
  confirmPassword: '',
  firstName: '',
  lastName: '',
  password: '',
};

function AcceptInviteContainer() {
  const { token } = useParams();
  const history = useHistory();

  const [view, setView] = useState('loading');
  const [invite, setInvite] = useState(null);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [userData, setUserData] = useState(INITIAL_DATA);
  const [validationErrors, setValidationErrors] = useState({});
  const [saving, setSaving] = useState(false);

  async function fetchInvite() {
    let loadedInvite = null;
    try {
      const { data } = (await api.get(`/invite/${token}`, false)).data;
      loadedInvite = data;
    } catch (e) {
      // Silently ignore - `loadedInvite` will be null so will show an error
    }

    if (loadedInvite) {
      setInvite(loadedInvite);
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchInvite();
  });

  function onChange(key, value) {
    const updatedData = userData;
    updatedData[key] = value;
    setUserData({ ...updatedData });
  }

  function validateData() {
    const fieldErrors = {};

    if (!userData.password) {
      fieldErrors.password = true;
    }
    if (!userData.confirmPassword) {
      fieldErrors.confirmPassword = true;
    }
    if (!userData.firstName) {
      fieldErrors.firstName = true;
    }
    if (!userData.lastName) {
      fieldErrors.lastName = true;
    }

    return fieldErrors;
  }

  function getPostData() {
    return {
      first_name: userData.firstName,
      last_name: userData.lastName,
      password: userData.password,
      invite_token: token,
    };
  }

  async function onSubmit() {
    const fieldErrors = validateData();
    setValidationErrors(fieldErrors);

    if (Object.keys(fieldErrors).length > 0) {
      return;
    }

    if (userData.password !== userData.confirmPassword) {
      toast('Passwords do not match');
      return;
    }

    setSaving(true);

    let user = null;
    const postData = getPostData();

    try {
      const { data } = (await api.post('/user', postData, false)).data;
      if (data.object === 'user') {
        user = data;
      }
    } catch (e) {
      let error = 'Something went wrong. Please try again';
      if (e && e.response && e.response.data) {
        const { code } = e.response.data;
        if (code === 'validation_password') {
          error = 'Please enter a valid password';
        }
        if (code === 'already_exists') {
          error = 'This email is already signed up. Try logging in instead.';
        }
        if (code === 'invalid_token') {
          error = 'This invite is invalid. You will need to be re-invited.';
        }
      }

      toast(error);
    }

    if (user) {
      let loginSuccess = false;
      try {
        await auth.login(invite.email, userData.password, invite.business.auth_tenant_id);

        // Need to trigger a user load so the account is added to the accounts cache
        await userUtil.refreshUser();

        loginSuccess = true;
      } catch (e) {
        // Silently ignore - loginSuccess will still be false so will redirect to /login
      }

      setSaving(false);

      if (loginSuccess) {
        history.push('/');
      } else {
        history.push('/login');
      }
    } else {
      setSaving(false);
    }
  }

  return (
    <AcceptInvite
      data={userData}
      invite={invite}
      onChange={onChange}
      onSubmit={onSubmit}
      onToggleTermsAccepted={() => setTermsAccepted(!termsAccepted)}
      saving={saving}
      termsAccepted={termsAccepted}
      validationErrors={validationErrors}
      view={view}
    />
  );
}

export default AcceptInviteContainer;
