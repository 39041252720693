let user = null;

function getUser() {
  return user;
}

function setUser(newUser) {
  user = newUser;
}

function clearUser() {
  user = null;
}

export default {
  getUser,
  setUser,
  clearUser,
};
