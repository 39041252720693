/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import PopupSetting from './popup-setting.view';

function PopupSettingContainer(props) {
  const {
    chatPlugin,
    onChatPluginUpdated,
  } = props;

  const [popupSetting, setPopupSetting] = useState(chatPlugin.popup_setting);
  const [submitting, setSubmitting] = useState(false);

  async function onSave() {
    setSubmitting(true);

    let success = false;
    try {
      await api.patch(`/chat_plugin/${chatPlugin.id}`, { popup_setting: popupSetting });
      success = true;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (success) {
      toast('Settings saved successfully');
      onChatPluginUpdated({ ...chatPlugin, popup_setting: popupSetting });
    } else {
      toast('Could not update settings. Something went wrong.');
    }
  }

  return (
    <PopupSetting
      chatPlugin={chatPlugin}
      onPopupSettingChanged={setPopupSetting}
      onSave={onSave}
      popupSetting={popupSetting}
      submitting={submitting}
    />
  );
}

export default PopupSettingContainer;
