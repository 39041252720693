/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View imports ------ */
import AddDomain from './add-domain.view';

function AddDomainContainer(props) {
  const {
    chatPlugin,
    onCancel,
    onDomainCreated,
  } = props;

  const [domain, setDomain] = useState('');
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit(ev) {
    ev.preventDefault();

    if (!domain || !domain.trim()) {
      setError(true);
      return;
    }

    setError(false);
    setSubmitting(true);

    let domainUrl = domain.trim();

    const needsHttp = !(domainUrl.startsWith('http://') || domainUrl.startsWith('https://'));
    if (needsHttp) {
      domainUrl = `http://${domainUrl}`;
    }

    domainUrl = new URL(domainUrl).host;

    let createdDomain = null;
    try {
      const { data } = (await api.post('/chat_plugin_domain', { chat_plugin: chatPlugin.id, domain: domainUrl })).data;
      createdDomain = data;
    } catch (e) {
      if (e && e.response && e.response.data) {
        const { code } = e.response.data;
        if (code === 'already_exists') {
          toast('This website has already been added');
          setSubmitting(false);
          return;
        }
      }

      // Silently ignore - `createdDomain` will be null so will show an error
    }

    setSubmitting(false);

    if (createdDomain) {
      onDomainCreated(createdDomain);
    } else {
      toast('Something went wrong. Please try again.');
    }
  }

  return (
    <AddDomain
      domain={domain}
      error={error}
      onCancel={onCancel}
      onDomainChanged={setDomain}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default AddDomainContainer;
