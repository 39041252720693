/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Rename from './rename.view';

function RenameContainer(props) {
  const {
    category,
    onCancel,
    onNameChanged,
  } = props;

  const [name, setName] = useState(category.name);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  async function onSubmit(ev) {
    ev.preventDefault();

    if (!name) {
      setError(true);
      return;
    }

    setSubmitting(true);
    setError(false);

    let success = false;
    try {
      await api.patch(`/category/${category.id}`, { name });
      success = true;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (success) {
      onNameChanged(name);
    } else {
      toast('Could not rename. Something went wrong.');
    }
  }

  return (
    <Rename
      error={error}
      name={name}
      onCancel={onCancel}
      onNameChanged={setName}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default RenameContainer;
