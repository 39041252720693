/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import PlainButton from 'common/plain-button';

function Delete(props) {
  const {
    onCancel,
    onConfirm,
    submitting,
  } = props;

  return (
    <div className="pt-4 pb-6 pr-4 border-t border-gray-200">
      <p className="text-sm text-gray-800 mb-1 font-semibold">
        Are you sure you want to delete this category?
      </p>

      <p className="text-sm text-gray-800 mb-6">
        Note that any conversations currently assigned to this category will be
        set to &quot;No category&quot;.
      </p>

      <Button
        destructive
        loading={submitting}
        onClick={onConfirm}
      >
        Yes, delete this category
      </Button>
      <PlainButton
        className="ml-4"
        color="gray"
        onClick={onCancel}
      >
        Cancel
      </PlainButton>
    </div>
  );
}

export default Delete;
