/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helper imports ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View imports ------ */
import AddCategory from './add-category.view';

function AddCategoryContainer(props) {
  const {
    categorySetId,
    onCancel,
    onCategoryAdded,
    parent,
  } = props;

  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit(ev) {
    ev.preventDefault();

    if (!name || !name.trim()) {
      setError(true);
      return;
    }

    setError(false);
    setSubmitting(true);

    const postData = {
      name,
      category_set: categorySetId,
      parent_category: parent.id,
    };

    let category = null;
    try {
      const { data } = (await api.post('/category', postData)).data;
      category = data;
    } catch (e) {
      // Silently ignore.
    }

    setSubmitting(false);

    if (category) {
      setName('');
      onCategoryAdded(category);
    } else {
      toast('Something went wrong. Please try again.');
    }
  }

  return (
    <AddCategory
      error={error}
      name={name}
      onCancel={onCancel}
      onNameChanged={setName}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default AddCategoryContainer;
