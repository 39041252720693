/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Checkbox from 'common/checkbox';

/* ------ Local components ------ */
import Deleting from './deleting';
import Resend from './resend';

function Settings(props) {
  const {
    onCancel,
    onDeleted,
    onShowDeleting,
    onShowResending,
    onToggleAdmin,
    totalUsers,
    updating,
    user,
    view,
  } = props;

  function renderNormalView() {
    return (
      <>
        <p className="text-gray-600 text-xs uppercase font-medium">Settings</p>
        <div>
          {user.object === 'invite' && (
            <button
              className="my-4 flex items-center text-sm font-medium text-gray-800"
              onClick={onShowResending}
              type="button"
            >
              <div className="w-8 h-8 rounded-lg bg-gray-200 flex items-center justify-center mr-2">
                <img alt="" className="w-4" src="/assets/icons/mailbox.svg" />
              </div>
              Resend email
            </button>
          )}

          {user.object === 'user' && (
            <div className="flex items-center my-4 ml-2">
              <Checkbox
                className="shadow-md"
                disabled={updating}
                onSelect={onToggleAdmin}
                checked={user.type === 'admin'}
              />

              <p className="text-xs text-gray-600 font-semibold ml-4">Admin</p>
            </div>
          )}

          {(user.object === 'invite' || totalUsers > 1) && (
            <div>
              <button
                className="flex items-center text-sm font-medium text-gray-800"
                onClick={onShowDeleting}
                type="button"
              >
                <div className="w-8 h-8 rounded-lg bg-gray-200 flex items-center justify-center mr-2">
                  <img alt="" className="w-4" src="/assets/icons/trash.svg" />
                </div>
                Delete
              </button>
            </div>
          )}
        </div>
      </>
    );
  }

  function renderDeleting() {
    return (
      <Deleting
        onCancel={onCancel}
        onDeleted={onDeleted}
        user={user}
      />
    );
  }

  function renderResending() {
    return (
      <Resend
        inviteId={user.id}
        onCancel={onCancel}
      />
    );
  }

  return (
    <div className="border-t py-6 pr-6">
      {view === 'deleting' && renderDeleting()}
      {view === 'resending' && renderResending()}
      {view === 'main' && renderNormalView()}
    </div>

  );
}

export default Settings;
