/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Details from './details.view';

function DetailsContainer(props) {
  const {
    account,
    onAccountUpdated,
  } = props;

  const [name, setName] = useState(account.name);
  const [editing, setEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  async function onSave(ev) {
    ev.preventDefault();

    if (!name || name.trim().length === 0) {
      return;
    }

    setSubmitting(true);

    let updatedAccount = null;
    try {
      const { data } = (await api.patch('/connect_account', { name })).data;
      updatedAccount = data;
    } catch (e) {
      toast('Something went wrong. Please try again.');
    }

    setSubmitting(false);

    if (updatedAccount) {
      onAccountUpdated();
      setEditing(false);
    }
  }

  return (
    <Details
      editing={editing}
      name={name}
      onEdit={() => setEditing(true)}
      onNameChanged={setName}
      onSave={onSave}
      submitting={submitting}
    />
  );
}

export default DetailsContainer;
