/* ------ Module imports ------ */
import React from 'react';
import moment from 'moment';

/* ------ Local components ------ */
import Delete from './delete';
import Options from './options';

function ApiKey(props) {
  const {
    apiKey,
    deleting,
    onCancelDeleting,
    onDeleted,
    onStartDeleting,
  } = props;

  return (
    <div className="border-b last:border-b-0 border-gray-200">
      <div className="px-6 py-4">
        <div>
          <div className="flex justify-between">
            <div>
              <p className="text-gray-800 font-medium">{apiKey.name}</p>
              <p className="mt-1 text-gray-600 text-sm">{apiKey.key}</p>
            </div>

            {!deleting && <Options onDelete={onStartDeleting} />}
          </div>

          <p className="mt-4 text-sm text-green-500">
            {apiKey.last_used ? `Last used ${moment(apiKey.last_used).format('DD MM YYYY')}` : 'Never used'}
          </p>
        </div>
      </div>

      {deleting && (
        <Delete
          apiKey={apiKey}
          onCancel={onCancelDeleting}
          onDeleted={onDeleted}
        />
      )}
    </div>
  );
}

export default ApiKey;
