/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';

/* ------ Common imports ------ */
import Button from 'common/button';
import Input from 'common/input';
import SimpleLayout from 'common/layout/simple';

function ResetPassword(props) {
  const {
    confirmPassword,
    errorCode,
    errors,
    newPassword,
    onConfirmPasswordChanged,
    onSubmit,
    onNewPasswordChanged,
    passwordUpdated,
    saving,
  } = props;

  function getErrorText() {
    if (errorCode === 'expired') {
      return 'The link you used to reset your password has expired.';
    }

    return 'Something went wrong. Please try again or contact us if you continue having trouble.';
  }

  function renderMainView() {
    return (
      <>
        {errorCode && <p className="text-center font-semibold text-gray-800">{getErrorText()}</p>}
        {!errorCode && (
          <>
            <p className="font-medium text-gray-800 mb-2 text-xl">Reset password</p>
            <p className="text-sm mb-6">Choose a new password</p>

            <Input
              className="mb-6"
              disabled={saving}
              error={errors.newPassword}
              label="New password"
              onChange={onNewPasswordChanged}
              type="password"
              value={newPassword}
            />
            <Input
              className="mb-10"
              disabled={saving}
              error={errors.confirmPassword}
              label="Confirm password"
              onChange={onConfirmPasswordChanged}
              type="password"
              value={confirmPassword}
            />

            <Button
              loading={saving}
              onClick={onSubmit}
            >
              SUBMIT
            </Button>
          </>
        )}
      </>
    );
  }

  function renderSuccessView() {
    return (
      <>
        <p className="font-medium text-gray-800 mb-2 text-xl">Your password was successfully updated</p>
        <Link
          className="text-sm text-gray-800 font-semibold hover:text-green-600"
          to="/login"
        >
          Click here to login
        </Link>
      </>
    );
  }

  return (
    <SimpleLayout>
      {passwordUpdated && renderSuccessView()}
      {!passwordUpdated && renderMainView()}
    </SimpleLayout>
  );
}

export default ResetPassword;
