/* ------ Module imports ------ */
import React from 'react';

function Message(props) {
  const {
    backgroundColor,
    foregroundColor,
    greeting,
    name,
  } = props;

  return (
    <div className="bg-white rounded-lg shadow-xl">
      <div className="p-4 flex items-end bg-gray-100 rounded-t-lg">
        <div className="ml-2">
          <p className="text-xs text-gray-600 font-semibold mb-1">{name || 'You'}</p>
          <p className="bg-gray-200 rounded-lg text-sm px-3 py-2 text-gray-800">{greeting || 'Hi, how can we help you?'}</p>
        </div>
      </div>
      <div className="p-4">
        <div
          className="flex items-center justify-center text-white text-sm font-medium rounded-lg px-4 py-2"
          style={{ backgroundColor: backgroundColor || '#0BE881' }}
        >
          <p style={{ color: foregroundColor || '#FFFF' }}>Get Started</p>
        </div>
      </div>
    </div>
  );
}

export default Message;
