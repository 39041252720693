/* ------ Module imports ------ */
import React, { useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';

/* ------ Style import ------ */
import 'react-image-crop/dist/ReactCrop.css';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ Common imports ------ */
import Button from 'common/button';

function Crop(props) {
  const {
    image,
    onCropped,
  } = props;

  const img = useRef(null);

  const [crop, setCrop] = useState({ unit: '%', width: '100', aspect: 1 });
  const [uploading, setUploading] = useState(false);

  function onImageLoaded(loadedImage) {
    img.current = loadedImage;
  }

  async function onUpload() {
    if (!crop || !image || !img.current) {
      return;
    }

    setUploading(true);

    const canvas = document.createElement('canvas');

    const pixelRatio = 2;
    const scaleX = img.current.naturalWidth / img.current.width;
    const scaleY = img.current.naturalHeight / img.current.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      img.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    canvas.toBlob(async blob => {
      const formData = new FormData();
      formData.append('file', blob, 'profile-picture.png');

      let createdFile = null;
      try {
        const { data } = (await api.post('/file', formData)).data;
        createdFile = data;
      } catch (e) {
        // Silently ignore.
      }

      setUploading(false);

      if (createdFile) {
        onCropped(createdFile);
      } else {
        toast('Something went wrong. Please try again.');
      }
    }, 'image/png');
  }

  return (
    <>
      <div className="p-6">
        <ReactCrop
          crop={crop}
          onChange={setCrop}
          onImageLoaded={onImageLoaded}
          src={image}
        />
      </div>
      <div className="border-t border-gray-200 px-6 py-4">
        <Button
          loading={uploading}
          onClick={onUpload}
        >
          Confirm
        </Button>
      </div>
    </>
  );
}

export default Crop;
