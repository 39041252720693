/* ------ Module imports ------ */
import React from 'react';

/* ------ Local components ------ */
import Bubble from './bubble';
import Message from './message';

function Preview(props) {
  const {
    backgroundColor,
    foregroundColor,
    greeting,
    name,
  } = props;

  return (
    <div className="w-72">
      <Message
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        greeting={greeting}
        name={name}
      />

      <div className="mt-4 text-right">
        <Bubble backgroundColor={backgroundColor} foregroundColor={foregroundColor} />
      </div>
    </div>
  );
}

export default Preview;
