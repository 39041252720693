/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Input from 'common/input';
import PlainButton from 'common/plain-button';

function AddDomain(props) {
  const {
    domain,
    error,
    onCancel,
    onDomainChanged,
    onSubmit,
    submitting,
  } = props;

  return (
    <form
      className="p-6 bg-gray-100 border-b border-gray-200"
      disabled={submitting}
      onSubmit={onSubmit}
    >
      <Input
        disabled={submitting}
        error={error}
        label="Website"
        onChange={onDomainChanged}
        placeholder="Eg. ralli.app"
        value={domain}
      />

      <div className="mt-6">
        <Button
          loading={submitting}
          type="submit"
        >
          Confirm
        </Button>
        <PlainButton
          className="ml-4"
          disabled={submitting}
          onClick={onCancel}
          type="button"
        >
          Cancel
        </PlainButton>
      </div>
    </form>
  );
}

export default AddDomain;
