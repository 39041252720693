/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import Webhooks from './webhooks.view';

function WebhooksContainer() {
  const [webhooks, setWebhooks] = useState(null);
  const [view, setView] = useState('loading');

  const [creatingWebhook, setCreatingWebhook] = useState(false);

  async function fetchWebhooks() {
    let loadedWebhooks = null;
    try {
      const { data } = (await api.get('/webhook')).data;
      loadedWebhooks = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedWebhooks) {
      setWebhooks(loadedWebhooks);
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchWebhooks();
  });

  return (
    <Webhooks
      creatingWebhook={creatingWebhook}
      onCancelCreatingWebhook={() => setCreatingWebhook(false)}
      onCreateWebhook={() => setCreatingWebhook(true)}
      onWebhookCreated={newHook => setWebhooks(webhooks.concat(newHook))}
      onWebhookDeleted={deletedHook => setWebhooks(webhooks.filter(k => k.id !== deletedHook.id))}
      view={view}
      webhooks={webhooks}
    />
  );
}

export default WebhooksContainer;
