/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Card from 'common/card';
import Input from 'common/input';
import PlainButton from 'common/plain-button';

function Details(props) {
  const {
    editing,
    name,
    onEdit,
    onNameChanged,
    onSave,
    submitting,
  } = props;

  return (
    <Card className="mt-10">
      <Card.Title>Details</Card.Title>

      <div className="flex px-6 py-4 border-b">
        <p className="text-sm font-semibold w-40 text-gray-800">Name</p>
        <div className="flex-1">
          {!editing && (
            <>
              <p className="text-sm text-gray-800">{name}</p>
              <PlainButton onClick={onEdit}>CHANGE</PlainButton>
            </>
          )}

          {editing && (
            <form
              className="w-full flex"
              disabled={submitting}
              onSubmit={onSave}
            >
              <Input
                className="flex-1 mr-2"
                disabled={submitting}
                error={!name || name.trim().length === 0}
                onChange={onNameChanged}
                value={name}
              />
              <Button type="submit">Save</Button>
            </form>
          )}
        </div>
      </div>
    </Card>
  );
}

export default Details;
