/* ------ Module imports ------ */
import React from 'react';

/* ------ Local components ------ */
import EmailRequiredSetting from './email-required-setting';
import PopupSetting from './popup-setting';

function Settings(props) {
  const {
    chatPlugin,
    onChatPluginUpdated,
  } = props;

  return (
    <>
      <EmailRequiredSetting
        chatPlugin={chatPlugin}
        onChatPluginUpdated={onChatPluginUpdated}
      />

      <PopupSetting
        chatPlugin={chatPlugin}
        onChatPluginUpdated={onChatPluginUpdated}
      />
    </>
  );
}

export default Settings;
