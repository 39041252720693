/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Resend from './resend.view';

function ResendContainer(props) {
  const {
    inviteId,
    onCancel,
  } = props;

  const [submitting, setSubmitting] = useState(false);

  async function onResend() {
    setSubmitting(true);

    let success = false;
    try {
      await api.post(`/invite/${inviteId}/resend`);
      success = true;
    } catch (e) {
      // Silently ignore - `success` will be false so will show an error
    }

    setSubmitting(false);

    if (success) {
      toast('Invite resent successfully.');
      onCancel();
    } else {
      toast('Could not resend invite. Something went wrong.');
    }
  }

  return (
    <Resend
      onCancel={onCancel}
      onConfirm={onResend}
      submitting={submitting}
    />
  );
}

export default ResendContainer;
