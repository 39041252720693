/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Card from 'common/card';
import Loading from 'common/loading';

/* ------ Local components ------ */
import NewWebhook from './new-webhook';
import Webhook from './webhook';

function Webhooks(props) {
  const {
    creatingWebhook,
    onCancelCreatingWebhook,
    onCreateWebhook,
    onWebhookCreated,
    onWebhookDeleted,
    view,
    webhooks,
  } = props;

  function renderError() {
    // TODO
    return null;
  }

  function renderWebhooks() {
    if (webhooks.length === 0) {
      return (
        <p className="text-gray-800 px-6 py-4">
          You don&apos;t have any webhooks set up
        </p>
      );
    }

    return webhooks.map(webhook => (
      <Webhook
        key={webhook.id}
        onDeleted={() => onWebhookDeleted(webhook)}
        webhook={webhook}
      />
    ));
  }

  function renderMain() {
    return (
      <>
        {creatingWebhook && (
          <NewWebhook
            onClose={onCancelCreatingWebhook}
            onWebhookCreated={onWebhookCreated}
          />
        )}
        {renderWebhooks()}
      </>
    );
  }

  return (
    <Card className="mt-10">
      <Card.Title action={{ label: '+ Setup new webhook', onClick: onCreateWebhook }}>Webhooks</Card.Title>
      {view === 'loading' && <Loading />}
      {view === 'error' && renderError()}
      {view === 'main' && renderMain()}
    </Card>
  );
}

export default Webhooks;
