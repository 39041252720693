/* ------ Module imports ------ */
import React from 'react';

/* ------ Local components ------ */
import Title from './title';

function Card(props) {
  const {
    children,
    className,
  } = props;

  return (
    <div className={`bg-white shadow-lg rounded-lg ${className || ''}`}>
      {children}
    </div>
  );
}

Card.Title = Title;

export default Card;
