/* ------ Helpers ------ */
import useDidMount from 'helpers/hooks/use-did-mount';

function useOnClickOutside(ref, callback) {
  function onClick(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  }

  useDidMount(() => {
    document.addEventListener('mousedown', onClick);

    return () => {
      document.removeEventListener('mousedown', onClick);
    };
  });
}

export default useOnClickOutside;
