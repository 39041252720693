/* ------ Module imports ------ */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ Common imports ------ */
import Loading from 'common/loading';

/* ------ View import ------ */
import ResetPassword from './reset-password.view';

function ResetPasswordContainer() {
  const { token } = useParams();

  const [errorCode, setErrorCode] = useState(null);
  const [loading, setLoading] = useState(true);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  async function fetchReset() {
    let success = false;
    try {
      await api.get(`/password_reset/${token}`, null, false);
      success = true;
    } catch (e) {
      if (e && e.response && e.response.data) {
        setErrorCode(e.response.data.code);
        setLoading(false);
        return;
      }
    }

    if (!success) {
      setErrorCode('failure');
    }

    setLoading(false);
  }

  useDidMount(() => {
    fetchReset();
  });

  function validate() {
    const validationErrors = {};

    if (!confirmPassword) {
      validationErrors.confirmPassword = true;
    }
    if (!newPassword) {
      validationErrors.newPassword = true;
    }

    return validationErrors;
  }

  async function onSubmit() {
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    if (confirmPassword !== newPassword) {
      toast('Passwords do not match');
      return;
    }

    setSaving(true);

    let success = false;
    try {
      await api.post(`/password_reset/${token}`, { password: newPassword }, false);
      success = true;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.code === 'invalid_password') {
        toast('Your password must container at least 2 of the following: numbers, lowercase letters, uppercase letters, and punctuation');
      } else {
        toast('Something went wrong. Please try again.');
      }
    }

    setSaving(false);

    if (success) {
      setPasswordUpdated(true);
    }
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <ResetPassword
      confirmPassword={confirmPassword}
      errorCode={errorCode}
      errors={errors}
      loading={loading}
      newPassword={newPassword}
      onConfirmPasswordChanged={setConfirmPassword}
      onNewPasswordChanged={setNewPassword}
      onSubmit={onSubmit}
      passwordUpdated={passwordUpdated}
      saving={saving}
    />
  );
}

export default ResetPasswordContainer;
