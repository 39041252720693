/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Checkbox from 'common/checkbox';
import Input from 'common/input';
import PlainButton from 'common/plain-button';

function NewWebhook(props) {
  const {
    error,
    events,
    onClose,
    onToggleEvent,
    onSubmit,
    onUrlChange,
    submitting,
    url,
  } = props;

  return (
    <div className="bg-gray-100 border-b border-gray-200">
      <form
        className="p-6 pt-4"
        disabled={submitting}
        onSubmit={onSubmit}
      >
        <div className="mb-6">
          <p>Which events do you want to subscribe this webhook to?</p>

          <div className="my-2 flex items-center">
            <Checkbox
              checked={events.conversations}
              onSelect={() => onToggleEvent('conversations')}
            />
            <span className="ml-2 text-xs font-mono text-gray-800">conversation.created</span>
          </div>
          <div className="my-2 flex items-center">
            <Checkbox
              checked={events.timelineItems}
              onSelect={() => onToggleEvent('timelineItems')}
            />
            <span className="ml-2 text-xs font-mono text-gray-800">timeline_item.created</span>
          </div>
        </div>

        <Input
          className="mb-6"
          disabled={submitting}
          error={error}
          label="Enter the url you want us to push events to"
          onChange={onUrlChange}
          placeholder="Eg. https://ralli.app/webhook"
          value={url}
        />

        <div className="flex items-center">
          <Button
            loading={submitting}
            type="submit"
          >
            CREATE WEBHOOK
          </Button>
          <PlainButton
            className="ml-6"
            color="gray"
            disabled={submitting}
            onClick={onClose}
            type="button"
          >
            CANCEL
          </PlainButton>
        </div>
      </form>
    </div>
  );
}

export default NewWebhook;
