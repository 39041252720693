/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';

/* ------ Common imports ------ */
import Card from 'common/card';
import Icon from 'common/icon';
import Loading from 'common/loading';

/* ------ Local components ------ */
import Add from './add';

function CategorySets(props) {
  const {
    adding,
    businessId,
    categorySets,
    onAdd,
    onCancelAdding,
    view,
  } = props;

  function renderUsedBy(inUseBy) {
    return (
      <div className="mt-4">
        <p className="text-gray-600 text-xs uppercase font-medium mb-2">Used by</p>
        {inUseBy.length > 0 && (
          <div className="flex">
            {inUseBy.map(channel => (
              <div className="flex items-center mr-4" key={channel.id}>
                <img
                  alt=""
                  className="w-3 mr-1"
                  src={`/assets/img/${channel.object === 'chat_plugin' ? 'web' : 'facebook'}.svg`}
                />
                <p className="text-gray-600 text-xs font-medium">{channel.name}</p>
              </div>
            ))}
          </div>
        )}

        {inUseBy.length === 0 && (
          <div className="flex items-center">
            <Icon className="text-yellow-500" icon="warning" />
            <p className="text-gray-800 text-sm ml-2">Not used by any messaging channels</p>
          </div>
        )}
      </div>
    );
  }

  function renderCategorySet(categorySet) {
    return (
      <Link
        className="flex items-center justify-between block px-6 py-4 border-b border-gray-300 last:border-b-0 hover:bg-gray-100"
        key={categorySet.id}
        to={`/category-set/${categorySet.id}`}
      >
        <div>
          <p className="text-gray-800 text-sm font-medium">{categorySet.name}</p>
          {renderUsedBy(categorySet.in_use_by)}
        </div>
        <div>
          <Icon
            className="text-gray-600"
            icon="right-chevron"
          />
        </div>
      </Link>
    );
  }

  function renderError() {
    return (
      <div className="px-6 py-4">
        <div className="bg-yellow-300 text-yellow-500 flex justify-center items-center rounded-full w-10 h-10">
          <Icon icon="warning" />
        </div>
        <p className="mt-6 font-medium text-gray-800 mb-2 text-xl">Could not load category sets</p>
        <p className="text-gray-800 text-sm">Something went wrong loading the category sets for this business. Please try again or contact us if you keep having this problem.</p>
      </div>
    );
  }

  return (
    <Card className="mt-10 overflow-hidden">
      <Card.Title action={adding ? {} : { label: '+ Add a category set', onClick: onAdd }}>
        Category Sets
      </Card.Title>

      {view === 'loading' && <Loading />}
      {view === 'error' && renderError()}
      {view === 'main' && (
        <>
          {adding && (
            <Add
              businessId={businessId}
              onCancel={onCancelAdding}
            />
          )}

          {categorySets.map(renderCategorySet)}
          {categorySets.length === 0 && (
            <div className="px-6 py-4">
              <p className="text-gray-800">No category sets have been created yet</p>
            </div>
          )}
        </>
      )}
    </Card>
  );
}

export default CategorySets;
