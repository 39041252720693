/* ------ Module imports ------ */
import React from 'react';

/* ------ Local components ------ */
import Field from './field';

function Input(props) {
  const {
    className,
    disabled,
    error,
    label,
    onChange,
    placeholder,
    suffix,
    type,
    value,
  } = props;

  return (
    <div className={className}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>
        {label && <span className="inline-block text-sm text-gray-800 font-medium mb-1">{label}</span>}
        <div className="relative">
          <Field
            disabled={disabled}
            onChange={onChange}
            placeholder={placeholder || ''}
            suffix={suffix}
            type={type}
            value={value}
          />
          {error && <div className="w-2 h-2 absolute mr-2 mt-2 top-0 right-0 bg-red-600 rounded-full" />}
        </div>
      </label>
    </div>
  );
}

export default Input;
