/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Invite from './invite.view';

function InviteContaier(props) {
  const {
    businessId,
    onClose,
    onInviteSent,
  } = props;

  const [email, setEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();

    setSubmitting(true);

    const postData = {
      business: businessId,
      email,
    };

    let invite = null;
    try {
      const { data } = (await api.post('/invite', postData)).data;
      invite = data;
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.code === 'invited') {
        toast('You have already invited this user. You can resend their invitation on the invited users page.');
        setSubmitting(false);
        return;
      }

      // Silently ignore - `invite` will stay null so will show an error
    }

    if (!invite) {
      toast('Please provide a valid email address');
      setSubmitting(false);
      return;
    }

    setSubmitting(false);

    toast('An invitation email has been sent');

    onInviteSent(invite);
    onClose();
  }

  return (
    <Invite
      email={email}
      onChange={setEmail}
      onClose={onClose}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default InviteContaier;
