/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';

/* ------ Common imports ------ */
import Card from 'common/card';
import Icon from 'common/icon';
import Loading from 'common/loading';

function ChatPlugins(props) {
  const {
    businessId,
    plugins,
    view,
  } = props;

  function renderChatPlugin(chatPlugin) {
    return (
      <Link
        className="flex items-center justify-between block px-6 py-4 border-b border-gray-300 last:border-b-0 hover:bg-gray-100"
        key={chatPlugin.id}
        to={`/chat-plugin/${chatPlugin.id}`}
      >
        <div className="flex items-center">
          <div className="rounded-lg rounded-lg w-10 h-10 bg-center bg-cover" style={{ backgroundImage: `url(${chatPlugin.profile_picture})` }} />
          <p className="ml-4 text-gray-800 text-sm font-medium">{chatPlugin.name}</p>
        </div>
        <div>
          <Icon
            className="text-gray-600"
            icon="right-chevron"
          />
        </div>
      </Link>
    );
  }

  function renderError() {
    return (
      <>
        <div className="bg-yellow-300 text-yellow-500 flex justify-center items-center rounded-full w-10 h-10">
          <Icon icon="warning" />
        </div>
        <p className="mt-6 font-medium text-gray-800 mb-2 text-xl">Could not load chat plugins</p>
        <p className="text-gray-800 text-sm">Something went wrong loading the chat plugins for this business. Please try again or contact us if you keep having this problem.</p>
      </>
    );
  }

  return (
    <Card className="mt-10 overflow-hidden">
      <Card.Title action={{ label: '+ Add a chat plugin', href: `/business/${businessId}/add-chat-plugin` }}>
        Website Chat Plugins
      </Card.Title>

      {view === 'loading' && <Loading />}
      {view === 'error' && renderError()}
      {view === 'main' && (
        <>
          {plugins.map(renderChatPlugin)}
          {plugins.length === 0 && (
            <div className="px-6 py-4">
              <p className="text-gray-800">No website plugins have been set up</p>
            </div>
          )}
        </>
      )}
    </Card>
  );
}

export default ChatPlugins;
