/* ------ Module imports ------ */
import React from 'react';

function User(props) {
  const {
    deleting,
    onCancelDelete,
    onConfirmDelete,
    onDelete,
    submitting,
    user,
  } = props;

  function renderMainView() {
    return (
      <>
        <p className="text-sm text-gray-800 font-medium">
          {user.object === 'invite' && `${user.email} (pending)`}
          {user.object === 'user' && `${user.first_name} ${user.last_name}`}
        </p>

        {onDelete && (
          <button
            className="text-xs font-medium text-gray-600 hover:text-red-600 focus:outline-none"
            onClick={onDelete}
            type="button"
          >
            Remove
          </button>
        )}
      </>
    );
  }

  function renderDeleteView() {
    return (
      <>
        <p className="text-sm text-gray-800 font-medium">
          <span>Are you sure you want to remove </span>
          <span>
            {user.object === 'user' && `${user.first_name} ${user.last_name}`}
            {user.object === 'invite' && `${user.email}`}
          </span>
        </p>
        <div>
          <button
            className="mr-4 text-xs font-medium text-gray-600 hover:text-blue-500 focus:outline-none"
            disabled={submitting}
            onClick={onCancelDelete}
            type="button"
          >
            No
          </button>
          <button
            className="text-xs font-medium text-red-600 hover:text-red-600 focus:outline-none"
            disabled={submitting}
            onClick={onConfirmDelete}
            type="button"
          >
            Yes
          </button>
        </div>
      </>
    );
  }

  return (
    <div
      className="my-4 flex items-center"
      key={user.id}
    >
      <div className="w-8 h-8 rounded-lg bg-gray-500 bg-cover bg-center" style={{ backgroundImage: `url(${user.profile_picture})` }} />
      <div className="ml-2 text-xs">
        {!deleting && renderMainView()}
        {deleting && renderDeleteView()}
      </div>
    </div>
  );
}

export default User;
