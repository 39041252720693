/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import DeleteCategorySet from './delete-category-set.view';

function DeleteCategorySetContainer(props) {
  const {
    categorySet,
    onCancelDelete,
    onDeleted,
  } = props;

  const [confirmValue, setConfirmValue] = useState('');
  const [view, setView] = useState('main');
  const [submitting, setSubmitting] = useState(false);

  async function onDelete() {
    if (confirmValue.toLowerCase().trim() !== 'delete') {
      return;
    }

    setSubmitting(true);

    let success = false;
    try {
      await api.remove(`/category_set/${categorySet.id}`);
      success = true;
    } catch (e) {
      // Silently ignore.
    }

    setSubmitting(false);

    if (success) {
      onDeleted();
    } else {
      toast('Could not delete category set. Something went wrong.');
    }
  }

  return (
    <DeleteCategorySet
      confirmValue={confirmValue}
      onCancelDelete={onCancelDelete}
      onConfirmDelete={onDelete}
      onConfirmValueChanged={setConfirmValue}
      onStartDelete={() => setView('deleting')}
      submitting={submitting}
      view={view}
    />
  );
}

export default DeleteCategorySetContainer;
