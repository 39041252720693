/* ------ Module imports ------ */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Add from './add.view';

function AddContainer(props) {
  const {
    businessId,
    onCancel,
  } = props;

  const history = useHistory();

  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);

  async function onSubmit(ev) {
    ev.preventDefault();

    if (!name || !name.trim()) {
      setError(true);
      return;
    }

    setError(false);
    setSubmitting(true);

    const postData = {
      business: businessId,
      name,
    };

    let createdSet = null;
    try {
      const { data } = (await api.post('/category_set', postData)).data;
      createdSet = data;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (createdSet) {
      history.push(`/category-set/${createdSet.id}`);
    } else {
      toast('Could not created category set. Something went wrong.');
    }
  }

  return (
    <Add
      error={error}
      name={name}
      onCancel={onCancel}
      onChange={setName}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default AddContainer;
