/* ------ Module imports ------ */
import axios from 'axios';

/* ------ Config imports ------ */
import config from 'config';

/* ------ Local imports ------ */
import accounts from './accounts';
import auth from './auth';
import history from './history';

async function request(url, method, data = null, authed = true) {
  const requestConfig = {
    url: config.urls.api + url,
    method,
    headers: {},
  };

  if (data) {
    requestConfig.data = data;
  }

  if (!authed) {
    return axios(requestConfig);
  }

  let token = null;
  try {
    token = await auth.getToken();
  } catch (e) {
    // Silently ignore - token will still be null so we will be redirected to login
  }

  if (!token) {
    accounts.removeCurrentAccount();
    history.push('/login');
    return null;
  }

  requestConfig.headers.Authorization = `Bearer ${token}`;
  return axios(requestConfig);
}

function get(url, authed = true) {
  return request(url, 'GET', null, authed);
}

function post(url, data, authed = true) {
  return request(url, 'POST', data, authed);
}

function put(url, data, authed = true) {
  return request(url, 'PUT', data, authed);
}

function patch(url, data, authed = true) {
  return request(url, 'PATCH', data, authed);
}

function remove(url, data, authed = true) {
  return request(url, 'DELETE', data, authed);
}

export default {
  get,
  post,
  put,
  patch,
  remove,
};
