/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Checkbox from 'common/checkbox';
import Icon from 'common/icon';
import Input from 'common/input';
import Loading from 'common/loading';
import SimpleLayout from 'common/layout/simple';

function AcceptInvite(props) {
  const {
    data,
    invite,
    onChange,
    onSubmit,
    onToggleTermsAccepted,
    saving,
    termsAccepted,
    validationErrors,
    view,
  } = props;

  const {
    confirmPassword,
    firstName,
    lastName,
    password,
  } = data;

  function renderLoading() {
    return (
      <Loading />
    );
  }

  function renderError() {
    // TODO.
    return null;
  }

  function renderMain() {
    return (
      <>
        <p className="font-medium text-gray-800 mb-2 text-xl">
          <span className="text-gray-600">Sign up to </span>
          <span>{invite.business.name}</span>
        </p>

        <p className="text-gray-800 text-sm mb-10">
          {`Let’s create your account to use with ${invite.business.name} on Ralli.`}
        </p>

        <div className="mb-6">
          <span className="inline-block text-sm text-gray-800 font-medium mb-1">Email</span>
          <div className="flex border border-gray-300 bg-gray-100 rounded-lg focus-within:border-blue-500 overflow-hidden">
            <p className="w-full py-2 px-3 text-sm">{invite.email}</p>
          </div>
          <p className="mt-2 text-xs font-medium text-gray-600">This is the email address that was invited to join Ralli. If it is incorrect, get the person who invited you to send the invite to your correct email address.</p>
        </div>

        <div className="mb-6">
          <Input
            error={validationErrors.password}
            label="Password"
            onChange={nv => onChange('password', nv)}
            type="password"
            value={password}
          />
          <p className="mt-2 text-xs font-medium text-gray-600">Password must be longer than 6 characters and not contain any spaces.</p>
        </div>

        <Input
          className="mb-6"
          error={validationErrors.confirmPassword}
          label="Confirm Password"
          onChange={nv => onChange('confirmPassword', nv)}
          type="password"
          value={confirmPassword}
        />

        <div className="flex w-full mb-6">
          <Input
            className="flex-1 mr-2"
            error={validationErrors.firstName}
            label="First Name"
            onChange={nv => onChange('firstName', nv)}
            value={firstName}
          />
          <Input
            className="flex-1 ml-2"
            error={validationErrors.lastName}
            label="Last Name"
            onChange={nv => onChange('lastName', nv)}
            value={lastName}
          />
        </div>

        <div className="flex w-full mb-10 items-center">
          <Checkbox
            checked={termsAccepted}
            onSelect={onToggleTermsAccepted}
          />
          <p className="ml-2 text-sm text-gray-800 font-medium">
            I accept Ralli&apos;s&nbsp;
            <a
              className="text-blue-500 focus:text-blue-600 hover:text-blue-600"
              href="https://ralli.app/legals/terms-and-conditions"
              rel="noopener noreferrer"
              target="_blank"
            >
              terms and conditions
            </a>
            &nbsp;and&nbsp;
            <a
              className="text-blue-500 focus:text-blue-600 hover:text-blue-600"
              href="https://ralli.app/legals/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
            >
              privacy policy
            </a>
          </p>
        </div>

        <Button
          disabled={!termsAccepted}
          loading={saving}
          onClick={onSubmit}
        >
          CONTINUE
          <Icon
            className="ml-2"
            icon="right-arrow"
          />
        </Button>
      </>
    );
  }

  return (
    <SimpleLayout>
      {view === 'loading' && renderLoading()}
      {view === 'error' && renderError()}
      {view === 'main' && renderMain()}
    </SimpleLayout>
  );
}

export default AcceptInvite;
