/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';

/* ------ Common imports ------ */
import Card from 'common/card';
import LoadingSpinner from 'common/loading-spinner';
import PlainButton from 'common/plain-button';

/* ------ Local components ------ */
import AddCategorySet from './add-category-set';
import HelpSection from './help-section';

function CategorySet(props) {
  const {
    adding,
    businessId,
    categorySet,
    chatPluginId,
    onAdd,
    onCancelAdding,
    onCategorySetUpdated,
    onRemove,
    onToggleRemoving,
    removing,
    submitting,
  } = props;

  return (
    <Card className="mt-10">
      <Card.Title>Category set</Card.Title>

      {adding && (
        <AddCategorySet
          businessId={businessId}
          chatPluginId={chatPluginId}
          onCancel={onCancelAdding}
          onCategorySetUpdated={onCategorySetUpdated}
        />
      )}

      {categorySet && (
        <div className="px-6 py-4">
          <p className="text-sm text-gray-800 font-medium">{categorySet.name}</p>
          {!removing && (
            <div className="mt-1 flex items-center">
              <Link
                className="text-xs text-blue-brand font-semibold"
                to={`/category-set/${categorySet.id}`}
              >
                View and edit categories
              </Link>

              <span className="inline-block w-1 h-1 rounded-full mx-2 bg-gray-700" />

              <button
                className="text-xs text-gray-800 font-semibold text-red-600"
                onClick={onToggleRemoving}
                type="button"
              >
                Remove from this chat plugin
              </button>
            </div>
          )}
          {removing && (
            <div className="mt-1">
              <p className="text-sm text-gray-800">Are you sure you want to remove this category set from this chat plugin?</p>
              <p className="text-sm text-gray-800">
                Note that this will remove the category from all current conversations from this
                chat plugin.
              </p>

              {!submitting && (
                <div className="mt-4 flex items-center">
                  <button
                    className="text-xs font-semibold text-red-600"
                    onClick={onRemove}
                    type="button"
                  >
                    Remove from chat plugin
                  </button>
                  <button
                    className="ml-4 text-xs font-semibold text-gray-800"
                    onClick={onToggleRemoving}
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              )}

              {submitting && (
                <LoadingSpinner small />
              )}
            </div>
          )}
        </div>
      )}

      {(!categorySet && !adding) && (
        <div className="p-6 text-center">
          <p className="text-gray-600 text-sm">You haven&apos;t added a category set yet</p>
          <PlainButton onClick={onAdd}>Select a category set</PlainButton>
        </div>
      )}

      <HelpSection />
    </Card>
  );
}

export default CategorySet;
