/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import SimpleLayout from 'common/layout/simple';

function VerifyEmail(props) {
  const {
    errorCode,
    onContinue,
    onGoBack,
    verified,
  } = props;

  function renderUnverified() {
    let text = 'There was an error verifiying your email address. Re-enter your new address to send a new link';
    if (errorCode === 'expired') {
      text = 'The link you used to verify your email has expired. You will need to re-enter your new address to send a new link.';
    }

    return (
      <div className="text-center">
        <p className="text-gray-800">{text}</p>

        <div className="mt-10">
          <Button onClick={onGoBack}>Edit email address</Button>
        </div>
      </div>
    );
  }

  function renderVerified() {
    return (
      <div className="text-center">
        <p className="text-gray-800">Thanks! Your email had been updated.</p>

        <div className="mt-10">
          <Button onClick={onContinue}>Log in with your new address</Button>
        </div>
      </div>
    );
  }

  return (
    <SimpleLayout>
      {verified && renderVerified()}
      {!verified && renderUnverified()}
    </SimpleLayout>
  );
}

export default VerifyEmail;
