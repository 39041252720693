/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Helpers ------ */
import accounts from 'helpers/accounts';
import auth from 'helpers/auth';
import history from 'helpers/history';

/* ------ Local components ------ */
import Popover from './popover';

function Nav() {
  const { user } = useContext(PlatformContext);

  const [popoverVisible, setPopoverVisible] = useState(false);

  function onLogout() {
    /* ------ Close the popover manually so we don't call set state on unmounted component ------ */
    setPopoverVisible(false);

    accounts.removeCurrentAccount();
    auth.logout();
    history.push('/login');
  }

  function onShowPopover() {
    if (!popoverVisible) {
      setPopoverVisible(true);
    }
  }

  return (
    <div className={`fixed top-0 right-0 w-full ${user.type === 'admin' ? 'xl:pl-80' : ''} z-40`}>
      <div className="w-full bg-white border-b border-gray-200 flex items-center justify-between pl-6">
        <div className="flex items-center">
          <Link to="/">
            <img
              alt="Ralli logo"
              className="w-8"
              src="/assets/img/logo-round.svg"
            />
          </Link>
        </div>

        <div className="flex">
          <Link
            className="p-4 flex items-center hover:bg-gray-200"
            to="/settings"
          >
            <img
              alt=""
              className="w-5"
              src="/assets/icons/settings.svg"
            />
          </Link>
          <div className="relative">
            <button
              className="focus:outline-none px-6 py-4 flex items-center bg-white hover:bg-gray-200"
              onClick={onShowPopover}
              type="button"
            >
              <div
                className="bg-gray-400 rounded-full w-8 h-8 bg-cover bg-center"
                style={{ backgroundImage: `url(${user.profile_picture})` }}
              />

              <div className="ml-2">
                <i className="text-xs ralli-icon-down-chevron text-gray-600" />
              </div>
            </button>

            {popoverVisible && (
              <div className="relative">
                <Popover
                  onClose={() => setPopoverVisible(false)}
                  onLogout={onLogout}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
