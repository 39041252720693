export default {
  env: 'staging',
  urls: {
    api: 'https://staging-connect-api.ralli.app',
    chatPlugin: 'https://staging-plugin.ralli.app',
  },
  firebase: {
    apiKey: 'AIzaSyBNYZBTJick456sb9LEuyKZa3Bel8juqLM',
    authDomain: 'ralli-ab1e1.firebaseapp.com',
  },
  facebook: {
    appId: '745789565950307',
    permissions: 'pages_show_list,pages_messaging',
    apiUrl: 'https://graph.facebook.com/v5.0',
  },
  sentry: {
    dsn: 'https://00ccf3c2e0684f5eab37ce70262ba639@o340590.ingest.sentry.io/5608752',
  },
};
