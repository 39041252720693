/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import LoadingSpinner from 'common/loading-spinner';

function Loading(props) {
  const {
    small,
  } = props;

  let className = 'flex items-center justify-center';

  if (small) {
    className += ' w-4 h-4 m-4';
  } else className += ' h-full w-full p-10';

  return (
    <div className={className}>
      <LoadingSpinner />
    </div>
  );
}

export default Loading;
