/* ------ Module imports ------ */
import React from 'react';

function Tab(props) {
  const {
    children,
    onClick,
    selected,
  } = props;

  let buttonClass = 'focus:outline-none uppercase font-semibold text-xs text-gray-600';
  if (selected) {
    buttonClass += ' text-green-500';
  }

  return (
    <div className="relative py-3 ml-6 first:ml-0">
      <button
        className={buttonClass}
        onClick={onClick}
        type="button"
      >
        {children}
      </button>

      {selected && (
        <div className="absolute bottom-0 left-0 right-0 w-full bg-green-500 h-1 -mb-px" />
      )}
    </div>
  );
}

export default Tab;
