/* ------ Module imports ------ */
import React from 'react';
import { Link } from 'react-router-dom';

/* ------ Common imports ------ */
import Card from 'common/card';
import Icon from 'common/icon';
import Loading from 'common/loading';
import PlainButton from 'common/plain-button';

function Home(props) {
  const {
    businesses,
    name,
    view,
  } = props;

  if (view === 'loading') {
    return <Loading />;
  }

  if (view === 'error') {
    // TODO
    return null;
  }

  return (
    <>
      <h1 className="font-heading text-2xl font-medium text-gray-800">{name}</h1>
      <PlainButton href="/business/new">+ Create a new business</PlainButton>

      <Card className="mt-10 overflow-hidden">
        {businesses.map(business => (
          <Link
            className="border-b last:border-b-0 border-gray-200 flex w-full justify-between items-center px-6 py-4 text-gray-800 hover:bg-gray-100"
            key={business.id}
            to={`/business/${business.id}`}
          >
            {business.name}

            <Icon
              className="text-gray-500"
              icon="right-chevron"
            />
          </Link>
        ))}
        {businesses.length === 0 && (
          <div className="px-6 py-4">
            <p className="text-gray-800">You haven&apos;t created any businesses yet.</p>
          </div>
        )}
      </Card>
    </>
  );
}

export default Home;
