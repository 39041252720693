/* ------ Module imports ------ */
import React from 'react';
import { useClipboard } from 'use-clipboard-copy';

/* ------ Config ------ */
import config from 'config';

/* ------ Common imports ------ */
import Card from 'common/card';

function GeneratedCode(props) {
  const { chatPluginId } = props;

  const clipboard = useClipboard({ copiedTimeout: 1200 });

  const html = `<div id="ralli-app-root" data-plugin-id="${chatPluginId}"></div>

<script async defer src="${config.urls.chatPlugin}/scripts/sdk.js"></script>`;

  function onCopy() {
    clipboard.copy(html);
  }

  return (
    <Card className="mt-10">
      <div className="px-6 py-4 bg-white border-b border-gray-200 flex justify-between rounded-t-lg">
        <p className="font-medium text-gray-800 text">Generated code</p>
      </div>

      <p className="px-6 mt-6 text-sm text-gray-800 font-medium">
        Add the following code to every page of the website you would like Ralli to be visible on.
      </p>

      <div className="p-6 relative">
        <button
          className={`focus:outline-none absolute top-0 right-0 mt-8 mr-8 uppercase text-xs font-semibold border rounded-lg px-2 py-1 ${clipboard.copied ? 'bg-green-500 text-white border-green-500' : 'border-gray-200 text-gray-800 bg-gray-200 hover:bg-gray-300'}`}
          disabled={clipboard.copied}
          onClick={onCopy}
          type="button"
        >
          {clipboard.copied ? 'Copied' : 'Copy'}
        </button>

        <pre className="whitespace-pre-wrap p-4 text-xs text-gray-800 bg-gray-100 border border-gray-200 rounded-lg">
          <code>{html}</code>
        </pre>
      </div>
    </Card>
  );
}

export default GeneratedCode;
