/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import IconButton from 'common/icon-button';
import Input from 'common/input';

function Rename(props) {
  const {
    error,
    name,
    onCancel,
    onNameChanged,
    onSubmit,
    submitting,
  } = props;

  return (
    <form
      className="flex-1"
      disabled={submitting}
      onSubmit={onSubmit}
    >
      <div className="flex">
        <Input
          className="flex-1"
          error={error}
          onChange={onNameChanged}
          value={name}
        />
        <IconButton
          color="green"
          icon="check"
          loading={submitting}
          type="submit"
        />
        <IconButton
          color="gray"
          disabled={submitting}
          icon="cross"
          onClick={onCancel}
        />
      </div>
    </form>
  );
}

export default Rename;
