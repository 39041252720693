/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import IconButton from 'common/icon-button';
import Input from 'common/input';

function RenameCategorySet(props) {
  const {
    error,
    name,
    onCancel,
    onNameChanged,
    onSubmit,
    submitting,
  } = props;

  return (
    <form
      className="flex"
      disabled={submitting}
      onSubmit={onSubmit}
    >
      <Input
        className="flex-1"
        error={error}
        onChange={onNameChanged}
        value={name}
      />
      <IconButton
        color="green"
        icon="check"
        loading={submitting}
        type="submit"
      />
      <IconButton
        color="gray"
        disabled={submitting}
        icon="cross"
        onClick={onCancel}
        type="button"
      />
    </form>
  );
}

export default RenameCategorySet;
