/* ------ Module imports ------ */
import React from 'react';

function Domain(props) {
  const {
    deleting,
    domain,
    onCancelDelete,
    onConfirmDelete,
    onDelete,
    submitting,
  } = props;

  function renderDeleteView() {
    return (
      <>
        <p className="text-sm text-gray-800 font-medium">
          {`Are you sure you want to remove ${domain.domain}?`}
        </p>
        <div>
          <button
            className="mr-4 text-xs font-medium text-gray-600 hover:text-blue-500 focus:outline-none"
            disabled={submitting}
            onClick={onCancelDelete}
            type="button"
          >
            No
          </button>
          <button
            className="text-xs font-medium text-red-600 hover:text-red-600 focus:outline-none"
            disabled={submitting}
            onClick={onConfirmDelete}
            type="button"
          >
            Yes
          </button>
        </div>
      </>
    );
  }

  function renderMainView() {
    return (
      <>
        <p className="font-medium text-gray-800 text-sm">{domain.domain}</p>
        <button
          className="text-xs font-medium text-gray-600 hover:text-red-600 focus:outline-none"
          onClick={onDelete}
          type="button"
        >
          Remove
        </button>
      </>
    );
  }

  return (
    <div className="px-6 py-4 border-b border-b last:border-b-0">
      {!deleting && renderMainView()}
      {deleting && renderDeleteView()}
    </div>
  );
}

export default Domain;
