/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Card from 'common/card';
import Icon from 'common/icon';
import PlainButton from 'common/plain-button';

/* ------ Local components ------ */
import Crop from './crop';

function ProfilePicture(props) {
  const {
    onCropComplete,
    onUploadImage,
    profilePictureUrl,
    uploadedImage,
  } = props;

  return (
    <Card className="mt-10">
      <Card.Title>Profile picture</Card.Title>
      {(!profilePictureUrl && !uploadedImage) && (
        <div className="p-6">
          <div className="flex items-center">
            <button
              className="rounded-full w-16 h-16 border border-dashed border-gray-300 flex items-center justify-center hover:bg-gray-100 focus:outline-none focus:border-green-500"
              onClick={onUploadImage}
              type="button"
            >
              <Icon className="text-gray-600" icon="plus" />
            </button>
            <div className="ml-6">
              <p className="text-gray-800 font-medium">Add your business logo</p>
              <p className="text-sm text-gray-600">This will show up on your chat plugin when people start a conversation</p>
            </div>
          </div>
        </div>
      )}
      {(profilePictureUrl && !uploadedImage) && (
        <div className="p-6 flex items-center">
          <img
            alt="Profile"
            className="border border-gray-200 w-16 h-16 rounded-full"
            src={profilePictureUrl}
          />
          <div className="ml-4">
            <p className="text-sm text-gray-800">This will show up on your chat plugin when people start a conversation</p>
            <PlainButton onClick={onUploadImage}>Change profile picture</PlainButton>
          </div>
        </div>
      )}
      {uploadedImage && (
        <Crop
          image={uploadedImage}
          onCropped={onCropComplete}
        />
      )}
    </Card>
  );
}

export default ProfilePicture;
