/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import NewKey from './new-key.view';

function NewKeyContainer(props) {
  const {
    onClose,
    onKeyCreated,
  } = props;

  const [name, setName] = useState('');
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();

    if (!name || !name.trim()) {
      setError(true);
      return;
    }

    setError(false);
    setSubmitting(true);

    let apiKey = null;
    try {
      const { data } = (await api.post('/api_key', { name })).data;
      apiKey = data;
    } catch (err) {
      // Silently ignore
    }

    setSubmitting(false);

    if (!apiKey) {
      toast('Could not create api key. Something went wrong.');
      setSubmitting(false);
      return;
    }

    onKeyCreated(apiKey);
    onClose();
  }

  return (
    <NewKey
      error={error}
      name={name}
      onChange={setName}
      onClose={onClose}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default NewKeyContainer;
