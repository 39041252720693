/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ View import ------ */
import Webhook from './webhook.view';

function WebhookContainer(props) {
  const {
    onDeleted,
    webhook,
  } = props;

  const [deleting, setDeleting] = useState(false);

  return (
    <Webhook
      deleting={deleting}
      onCancelDeleting={() => setDeleting(false)}
      onDeleted={onDeleted}
      onStartDeleting={() => setDeleting(true)}
      webhook={webhook}
    />
  );
}

export default WebhookContainer;
