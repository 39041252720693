/* ------ Module imports ------ */
import React from 'react';

/* ------ Local components ------ */
import Delete from './delete';

function DangerZone(props) {
  const { chatPlugin } = props;

  return (
    <div className="mt-10 bg-white shadow-lg rounded-lg">
      <p className="font-medium text-gray-800 text px-6 py-4">Danger Zone</p>

      <Delete
        chatPlugin={chatPlugin}
      />
    </div>
  );
}

export default DangerZone;
