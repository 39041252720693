/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Helpers ------ */
import userUtil from 'helpers/user';

/* ------ View import ------ */
import Profile from './profile.view';

function ProfileContainer() {
  const { user } = useContext(PlatformContext);

  const [currentUser, setCurrentUser] = useState({ ...user });
  const [card, setCard] = useState(null);

  async function onUserUpdated(updatedUser) {
    setCurrentUser({ ...currentUser, ...updatedUser });
    userUtil.refreshUser();
  }

  return (
    <Profile
      card={card}
      onSetCard={setCard}
      onUserUpdated={onUserUpdated}
      user={currentUser}
    />
  );
}

export default ProfileContainer;
