/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import PlainButton from 'common/plain-button';

function Deleting(props) {
  const {
    onCancel,
    onConfirm,
    submitting,
    user,
  } = props;

  return (
    <div>
      <p className="text-sm text-gray-800 font-medium mb-1">
        <span>Are you sure you want to delete </span>
        <span className="font-semibold">
          {user.object === 'user' && `${user.first_name} ${user.last_name}`}
          {user.object === 'invite' && `${user.email}`}
        </span>
        ?
      </p>

      <p className="text-gray-800 text-sm mb-6">
        {user.object === 'user' && 'This will mean they can no longer reply to conversations on Ralli.'}
        {user.object === 'invite' && 'This will delete their invitation to join Ralli.'}
      </p>

      <Button
        destructive
        loading={submitting}
        onClick={onConfirm}
      >
        Delete
      </Button>
      <PlainButton
        className="ml-4"
        color="gray"
        disabled={submitting}
        onClick={onCancel}
      >
        Cancel
      </PlainButton>
    </div>
  );
}

export default Deleting;
