/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------- View imports ------ */
import Domains from './domains.view';

function DomainsContainer(props) {
  const {
    chatPlugin,
    onDomainsUpdated,
  } = props;

  const [adding, setAdding] = useState(false);

  function onDomainCreated(domain) {
    const updatedDomains = chatPlugin.domains.concat(domain);
    setAdding(false);
    onDomainsUpdated(updatedDomains);
  }

  function onDomainDeleted(domain) {
    const updatedDomains = chatPlugin.domains.filter(d => d.id !== domain.id);
    onDomainsUpdated(updatedDomains);
  }

  return (
    <Domains
      adding={adding}
      chatPlugin={chatPlugin}
      onAddDomain={setAdding}
      onDomainCreated={onDomainCreated}
      onDomainDeleted={onDomainDeleted}
    />
  );
}

export default DomainsContainer;
