/* ------ Module imports ------ */
import { useCallback, useEffect } from 'react';

const noop = () => {};

function useDidMount(fn) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedFn = useCallback(fn, []);

  useEffect(() => {
    const unregisterFn = memoizedFn();

    return unregisterFn || noop;
  }, [memoizedFn]);
}

export default useDidMount;
