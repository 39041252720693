/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';

/* ------ Local components ------ */
import Popover from './popover';
import Rename from './rename';

function Header(props) {
  const {
    category,
    deleting,
    depth,
    expanded,
    hasParent,
    onAddSubcategory,
    onCancelRenaming,
    onDelete,
    onRename,
    onRenamed,
    onToggleExpanded,
    onTogglePopoverVisible,
    popoverVisible,
    renaming,
  } = props;

  const canAddSubcategory = (!category.users || !category.users.length)
    && (!category.invites || !category.invites.length)
    && depth < 4;

  return (
    <div className={`${hasParent ? 'border-t border-gray-200' : 'pl-6'} pr-3 py-2 flex items-center justify-between`}>
      <div>
        <button
          className="flex items-center text-gray-800 font-semibold text-sm focus:outline-none"
          disabled={deleting}
          onClick={onToggleExpanded}
          type="button"
        >
          <Icon
            className={`text-xs ${expanded ? 'text-blue-brand' : 'text-gray-600'} mr-4 ${deleting ? 'invisible' : ''}`}
            icon={expanded ? 'down-chevron' : 'right-chevron'}
          />
          {!renaming && <span className="text-gray-800">{category.name}</span>}
        </button>
      </div>

      {renaming && (
        <Rename
          category={category}
          onCancel={onCancelRenaming}
          onNameChanged={onRenamed}
        />
      )}

      {!renaming && (
        <div>
          <button
            className="flex items-center ml-2 border border-transparent hover:border-gray-200 bg-white hover:shadow px-3 py-2 rounded-lg focus:outline-none"
            onClick={onTogglePopoverVisible}
            type="button"
          >
            <Icon className="text-gray-800" icon="more" />
          </button>

          {popoverVisible && (
            <div className="relative">
              <Popover
                canAddSubcategory={canAddSubcategory}
                onAddSubcategory={onAddSubcategory}
                onClose={onTogglePopoverVisible}
                onDelete={onDelete}
                onRename={onRename}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Header;
