/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Icon from 'common/icon';

function ForgottenPassword(props) {
  const {
    email,
    onCancel,
    onResend,
    onSubmit,
    submitting,
    success,
  } = props;

  function renderHeader() {
    const title = success ? 'We\'ve sent you an email' : 'Reset password';
    const text = success
      ? 'Check your emails and follow the instructions to reset your password'
      : `Click the button below to send password reset instuctions to ${email}`;

    return (
      <div>
        <p className="font-medium text-gray-800 mb-1 text-2xl font-heading">{title}</p>
        <p className="text-gray-800 mb-10">{text}</p>
      </div>
    );
  }

  function renderSuccessView() {
    return (
      <div className="mt-10">
        <button
          className="text-sm text-gray-600 font-semibold hover:text-green-600"
          onClick={onResend}
          type="button"
        >
          Resend email
        </button>
      </div>
    );
  }

  function renderMainView() {
    return (
      <>
        <button
          className="relative focus:outline-none p-0 font-semibold text-sm disabled:cursor-not-allowed text-gray-600 focus:text-blue-600 hover:text-blue-600 disabled:text-gray-600 uppercase block mb-6"
          onClick={onCancel}
          type="button"
        >
          <Icon
            className="mr-2"
            icon="left-arrow"
          />
          <span>Back</span>
        </button>

        <Button
          className="py-3 w-full"
          loading={submitting}
          onClick={onSubmit}
          type="button"
        >
          Send email
        </Button>
      </>
    );
  }

  return (
    <>
      {renderHeader()}

      {success && renderSuccessView()}
      {!success && renderMainView()}
    </>
  );
}

export default ForgottenPassword;
