/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Special import for add categories or users ------ */
import AddCategory from 'components/category-set/main/category/expanded/add-category';
import AddUser from 'components/category-set/main/category/expanded/add-user';

function Empty(props) {
  const {
    businessId,
    category,
    categorySetId,
    canAddSubcategory,
    onCategoryAdded,
    onCategoryUpdated,
  } = props;

  const [view, setView] = useState('empty');

  if (view === 'category') {
    return (
      <AddCategory
        categorySetId={categorySetId}
        onCancel={() => setView('empty')}
        onCategoryAdded={onCategoryAdded}
        parent={category}
      />
    );
  }

  if (view === 'user') {
    return (
      <div className="py-4 pr-4 border-t border-gray-200">
        <AddUser
          businessId={businessId}
          category={category}
          onCancel={() => setView('empty')}
          onCategoryUpdated={onCategoryUpdated}
        />
      </div>
    );
  }

  return (
    <div className="border-t border-gray-200">
      {canAddSubcategory && (
        <div className="mt-3 mb-1">
          <button
            className="inline-block text-blue-brand font-medium text-xs"
            onClick={() => setView('category')}
            type="button"
          >
            Add subcategories
          </button>
        </div>
      )}
      <div className={`${canAddSubcategory ? 'mt-1' : 'mt-2'} mb-3`}>
        <button
          className="inline-block text-blue-brand font-medium text-xs"
          onClick={() => setView('user')}
          type="button"
        >
          Add users
        </button>
      </div>
    </div>
  );
}

export default Empty;
