/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Common imports ------ */
import SimpleLayout from 'common/layout/simple';

/* ------ Local components ------ */
import Accounts from './accounts';
import Email from './email';
import ForgottenPassword from './forgotten-password';
import User from './user';

function Login() {
  const [email, setEmail] = useState(null);
  const [account, setAccount] = useState(null);
  const [forgottenPassword, setForgottenPassword] = useState(false);

  function renderHeader() {
    if (forgottenPassword) {
      return null;
    }

    let text = null;
    if (!email) {
      text = 'Enter your email address';
    } else if (!account) {
      text = 'Select which account you want to login to';
    } else {
      text = `Enter your password for ${account.name}`;
    }

    return (
      <div>
        <p className="font-medium text-gray-800 mb-1 text-2xl font-heading">Login to Ralli Connect</p>
        <p className="text-gray-800 mb-10">{text}</p>
      </div>
    );
  }

  function renderInner() {
    if (forgottenPassword) {
      return (
        <ForgottenPassword
          account={account}
          email={email}
          onCancel={() => setForgottenPassword(false)}
        />
      );
    }

    if (!email) {
      return <Email onSubmit={setEmail} />;
    }

    if (!account) {
      return (
        <Accounts
          email={email}
          onBack={() => setEmail(null)}
          onSubmit={setAccount}
        />
      );
    }

    return (
      <User
        account={account}
        email={email}
        onBack={() => setAccount(null)}
        onForgotPassword={() => setForgottenPassword(true)}
      />
    );
  }

  return (
    <SimpleLayout>
      {renderHeader()}
      {renderInner()}
    </SimpleLayout>
  );
}

export default Login;
