/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import ApiKeys from './api-keys.view';

function ApiKeysContainer() {
  const [apiKeys, setApiKeys] = useState(null);
  const [view, setView] = useState('loading');

  const [creatingKey, setCreatingKey] = useState(false);

  async function fetchApiKeys() {
    let loadedKeys = null;
    try {
      const { data } = (await api.get('/api_key')).data;
      loadedKeys = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedKeys) {
      setApiKeys(loadedKeys);
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchApiKeys();
  });

  return (
    <ApiKeys
      apiKeys={apiKeys}
      creatingKey={creatingKey}
      onCancelCreatingKey={() => setCreatingKey(false)}
      onCreateKey={() => setCreatingKey(true)}
      onKeyCreated={newKey => setApiKeys(apiKeys.concat(newKey))}
      onKeyDeleted={deletedKey => setApiKeys(apiKeys.filter(k => k.id !== deletedKey.id))}
      view={view}
    />
  );
}

export default ApiKeysContainer;
