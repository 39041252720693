/* ------ Module imports ------ */
import React, { useContext } from 'react';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Common imports ------ */
import Card from 'common/card';
import Icon from 'common/icon';
import Loading from 'common/loading';

/* ------ Local components ------ */
import User from './user';

function Users(props) {
  const {
    onUserDeleted,
    users,
    view,
  } = props;

  const { user } = useContext(PlatformContext);

  function renderError() {
    return (
      <>
        <div className="bg-yellow-300 text-yellow-500 flex justify-center items-center rounded-full w-10 h-10">
          <Icon icon="warning" />
        </div>
        <p className="mt-6 font-medium text-gray-800 mb-2 text-xl">Could not load users</p>
        <p className="text-gray-800 text-sm">Something went wrong loading users. Please try again or contact us if you keep having this problem.</p>
      </>
    );
  }

  function renderLoading() {
    return <Loading />;
  }

  function renderUsers() {
    return users.map(u => (
      <User
        key={u.id}
        isCurrentUser={user.id === u.id}
        onDeleted={() => onUserDeleted(u)}
        user={u}
      />
    ));
  }

  return (
    <Card className="mt-10">
      <Card.Title>User</Card.Title>

      {view === 'error' && renderError()}
      {view === 'loading' && renderLoading()}
      {view === 'main' && renderUsers()}
    </Card>
  );
}

export default Users;
