/* ------ Module imports ------ */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

/* ------ View import ------ */
import Main from './main.view';

function MainContainer(props) {
  const {
    categories,
    categorySet,
    onCategoryAdded,
    onCategoryDeleted,
    onCategoryUpdated,
    onRenamed,
    onReorder,
  } = props;

  const history = useHistory();

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [renaming, setRenaming] = useState(false);

  function onNameChanged(name) {
    onRenamed(name);
    setRenaming(false);
  }

  function onSetDeleting() {
    setPopoverVisible(false);
    setRenaming(false);
    setDeleting(true);
  }

  function onSetRenaming() {
    setPopoverVisible(false);
    setDeleting(false);
    setRenaming(true);
  }

  return (
    <Main
      categories={categories}
      categorySet={categorySet}
      deleting={deleting}
      onCancelDelete={() => setDeleting(false)}
      onCancelRename={() => setRenaming(false)}
      onCategoryAdded={onCategoryAdded}
      onCategoryDeleted={onCategoryDeleted}
      onCategorySetDeleted={() => history.push(`/business/${categorySet.business}`)}
      onCategoryUpdated={onCategoryUpdated}
      onNameChanged={onNameChanged}
      onReorder={onReorder}
      onSetDeleting={onSetDeleting}
      onSetRenaming={onSetRenaming}
      onTogglePopover={() => setPopoverVisible(!popoverVisible)}
      popoverVisible={popoverVisible}
      renaming={renaming}
    />
  );
}

export default MainContainer;
