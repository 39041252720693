/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ Common imports ------ */
import UserView from 'common/user';

function User(props) {
  const {
    category,
    onCategoryUpdated,
    user,
  } = props;

  const [deleting, setDeleting] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  async function onConfirm() {
    setSubmitting(true);

    let updatedCategory = null;
    const updatedUsers = {};

    if (user.object === 'invite') {
      updatedUsers.invites = category.invites
        .filter(u => u.id !== user.id)
        .map(u => u.id);
    } else {
      updatedUsers.users = category.users
        .filter(u => u.id !== user.id)
        .map(u => u.id);
    }

    try {
      const { data } = (await api.patch(`/category/${category.id}?expand[]=users&expand[]=invites`, updatedUsers)).data;
      if (data.object === 'category') {
        updatedCategory = data;
      }
    } catch (e) {
      // Silently ignore - updatedCategory will stay null so we will show an error toast
    }

    setSubmitting(false);

    if (!updatedCategory) {
      toast('Something went wrong. Please try again.');
      return;
    }

    onCategoryUpdated(updatedCategory);
  }

  return (
    <UserView
      deleting={deleting}
      onCancelDelete={() => setDeleting(false)}
      onConfirmDelete={onConfirm}
      onDelete={() => setDeleting(true)}
      submitting={submitting}
      user={user}
    />
  );
}

export default User;
