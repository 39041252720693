/* ------ Module imports ------ */
import React from 'react';
import ReactDOM from 'react-dom';

/* ------ Common imports ------ */
import Toast from 'common/toast';

let toastInProgress = false;
function toast(text) {
  if (toastInProgress) {
    return;
  }

  toastInProgress = true;
  const dom = <Toast>{text}</Toast>;
  const node = document.getElementById('toaster');

  ReactDOM.render(dom, node);

  setTimeout(() => {
    ReactDOM.unmountComponentAtNode(node);
    toastInProgress = false;
  }, 2000);
}

export default toast;
