/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Card from 'common/card';
import Icon from 'common/icon';
import Loading from 'common/loading';
import Tab from 'common/tab';

/* ------ Local components ------ */
import Invite from './invite';
import User from './user';

function UserList(props) {
  const {
    activeUsers,
    businessId,
    invitedUsers,
    onChangeTab,
    onCloseInvite,
    onInviteSent,
    onShowInvite,
    onUserDeleted,
    onUserUpdated,
    showInvite,
    tab,
    view,
  } = props;

  function renderErrorView() {
    return (
      <>
        <div className="bg-yellow-300 text-yellow-500 flex justify-center items-center rounded-full w-10 h-10">
          <Icon icon="warning" />
        </div>
        <p className="mt-6 font-medium text-gray-800 mb-2 text-xl">Could not load users</p>
        <p className="text-gray-800 text-sm">Something went wrong loading the users for this business. Please try again or contact us if you keep having this problem.</p>
      </>
    );
  }

  function renderLoadingView() {
    return <Loading />;
  }

  function renderUsers() {
    const users = tab === 'active' || invitedUsers.length === 0 ? activeUsers : invitedUsers;

    if (users.length === 0) {
      return (
        <div className="px-6 py-4">
          <p className="text-gray-800">There are no users signed up to this business.</p>
        </div>
      );
    }

    return users.map((user, i) => (
      <User
        isLastChild={i === users.length - 1}
        key={user.id}
        onUserDeleted={() => onUserDeleted(user)}
        onUserUpdated={onUserUpdated}
        totalUsers={activeUsers.length}
        user={user}
      />
    ));
  }

  function renderMainView() {
    return (
      <>
        {invitedUsers.length > 0 && (
          <div className="flex items-center px-6 border-b border-gray-200">
            <Tab
              onClick={() => onChangeTab('active')}
              selected={tab === 'active'}
            >
              Active Users
            </Tab>

            <Tab
              onClick={() => onChangeTab('invited')}
              selected={tab === 'invited'}
            >
              Invited Users
            </Tab>
          </div>
        )}

        {renderUsers()}
      </>
    );
  }

  return (
    <Card className="mt-10 overflow-hidden">
      <Card.Title action={showInvite ? {} : { label: '+ Invite a user', onClick: onShowInvite }}>
        Users
      </Card.Title>

      {view === 'error' && renderErrorView()}
      {view === 'loading' && renderLoadingView()}
      {view === 'main' && renderMainView()}

      {showInvite && (
        <Invite
          businessId={businessId}
          onClose={onCloseInvite}
          onInviteSent={onInviteSent}
        />
      )}
    </Card>
  );
}

export default UserList;
