/* ------ Module imports ------ */
import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

/* ------ Helpers imports ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Reorder from './reorder.view';

function ReorderContainer(props) {
  const {
    categories,
    categorySet,
    onCategoriesUpdated,
    onDone,
  } = props;

  const [loadingCategory, setLoadingCategory] = useState(null);

  function getErrorMessage(code) {
    switch (code) {
      case 'parent_has_users':
        return 'You can only add sub-categories to categories with no users';
      case 'tree_depth':
        return 'Maximum number of sub categories met';
      default:
        return 'Something went wrong. Please try again.';
    }
  }

  async function onMoveCategory(categoryId, sortIndex, parent) {
    const updateData = {
      sort_index: sortIndex,
      parent_category: parent || null,
    };

    try {
      const { data } = (await api.patch(`/category/${categoryId}`, updateData)).data;
      return data;
    } catch (e) {
      if (e.response.data.code) {
        toast(getErrorMessage(e.response.data.code));
      } else {
        toast('Something went wrong. Please try again.');
      }

      return null;
    }
  }

  /* ------ This function handles removing a category from the tree recursively ------ */
  function removeCategory(category, source) {
    if (category.id === source.droppableId) {
      const [removed] = category.children.splice(source.index, 1);
      category.children = [...category.children];
      return removed;
    }

    if (category.children) {
      return category.children
        .map(child => removeCategory(child, source))
        .find(child => !!child);
    }

    return null;
  }

  /* ------ This function handles inserting a category into the tree recursively ------ */
  async function insertCategory(category, toInsert, destinationIndex, parentId) {
    if (category.id === parentId) {
      if (!category.children) {
        category.children = [];
      }

      category.children.splice(destinationIndex, 0, toInsert);
      category.children = [...category.children];
      return;
    }

    if (category.children) {
      category.children.forEach(child => {
        insertCategory(child, toInsert, destinationIndex, parentId);
      });
    }
  }

  async function reorderCategories(source, destination) {
    setLoadingCategory(source.category.id);

    /* ------ Make a deep copy to store initial categories in case something goes wrong ------ */
    const initalCategories = JSON.parse(JSON.stringify(categories));
    const updatedCategories = categories;

    /**
     * First we start by removing the category from it's parent array.
     * Note that this is done differently based on whether the source was
     * a top-level category, or a child category.
     */
    let removed = null;
    if (source.droppableId === 'parent') {
      [removed] = updatedCategories.splice(source.index, 1);
    } else {
      removed = updatedCategories
        .map(cat => removeCategory(cat, source))
        .find(cat => !!cat);
    }

    /**
     * Because we do the category removal first, if we are inserting
     * back into the same list at a higher index, we need to decrease the index by 1
     * since there is now 1 less item below our destination index.
     */
    let destinationIndex = destination.index;
    if (destination.index > source.index && destination.droppableId === source.droppableId) {
      destinationIndex = destination.index - 1;
    }

    if (!removed) {
      toast('Something went wrong. Please try again.');
      setLoadingCategory(null);
      return;
    }

    /**
     * Now we insert the removed category back into the tree. If the destination is
     * the top level, we simply splice it in there. Otherwise, we hand off to a function
     * that recursively does the insert.
     */
    if (destination.droppableId === 'parent') {
      updatedCategories.splice(destinationIndex, 0, removed);
    } else {
      updatedCategories.forEach(cat => {
        insertCategory(cat, removed, destinationIndex, destination.droppableId);
      });
    }

    /**
     * Here, we make the call to the function that handles propagating the
     * update to the server.
     *
     * Note that we pass destinationIndex + 1 as the updated sort index, since
     * the API starts sort indices at 1 instead of 0.
     */
    const updatedCategory = await onMoveCategory(
      removed.id,
      destinationIndex + 1,
      destination.droppableId === 'parent' ? null : destination.droppableId,
    );

    /* ------ Handle an error by reverting categories to the initial state ------ */
    if (!updatedCategory) {
      toast('Something went wrong. Please try again.');
      onCategoriesUpdated(initalCategories);
      setLoadingCategory(null);
      return;
    }

    onCategoriesUpdated(updatedCategories);
    setLoadingCategory(null);
  }

  function onDragEnd(result) {
    const {
      destination,
      source,
    } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId
        && destination.index === source.index
    ) {
      return;
    }

    reorderCategories(source, destination);
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Reorder
        categories={categories}
        categorySet={categorySet}
        dragDisabled={!!loadingCategory}
        loadingCategory={loadingCategory}
        onDone={onDone}
        onDragEnd={onDragEnd}
      />
    </DndProvider>
  );
}

export default ReorderContainer;
