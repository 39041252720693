/* ------- Module imports ------ */
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

/* ------ Helpers ------ */
import accounts from 'helpers/accounts';
import auth from 'helpers/auth';
import history from 'helpers/history';
import useDidMount from 'helpers/hooks/use-did-mount';
import user from 'helpers/user';

/* ------ Routes ------ */
import AddChatPlugin from 'components/add-chat-plugin';
import Business from 'components/business';
import CategorySet from 'components/category-set';
import ChatPlugin from 'components/chat-plugin';
import CreateBusiness from 'components/create-business';
import Home from 'components/home';
import Profile from 'components/profile';
import Settings from 'components/settings';
import Unverified from 'components/unverified';

/* ------ Context ------ */
import PlatformContext from './context';

/* ------ Local components ------ */
import Nav from './nav';

function PlatformContainer() {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  async function checkUser() {
    const currentAccount = accounts.getCurrentAccount();
    if (!currentAccount) {
      history.push('/login');
      return;
    }

    await auth.init(currentAccount.connect_account.auth_tenant_id);

    const loadedUser = await user.getCurrentUser();

    if (loadedUser) {
      if (!loadedUser.email_verified) {
        history.push('/unverified');
      }

      setCurrentUser(loadedUser);
      setLoading(false);
    }
  }

  useDidMount(() => {
    checkUser();
    return user.registerHandler(setCurrentUser);
  });

  if (loading) {
    return null;
  }

  const context = {
    user: currentUser,
  };

  function renderUnverified() {
    return (
      <Switch>
        <Route path="/unverified" component={Unverified} />
      </Switch>
    );
  }

  function renderVerified() {
    return (
      <div>
        <Nav />

        <div className="relative">
          <div className="max-w-2xl mx-auto flex-1 px-4 pt-32 pb-16">
            <Switch>
              <Route path="/business/new" component={CreateBusiness} />
              <Route path="/business/:id/add-chat-plugin" component={AddChatPlugin} />
              <Route path="/business/:id" component={Business} />
              <Route path="/category-set/:id" component={CategorySet} />
              <Route path="/chat-plugin/:id" component={ChatPlugin} />
              <Route path="/profile" component={Profile} />
              <Route path="/settings" component={Settings} />
              <Route exact path="/" component={Home} />
              <Route path="*" render={() => <Redirect to="/" />} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }

  return (
    <PlatformContext.Provider value={context}>
      {currentUser.email_verified && renderVerified()}
      {!currentUser.email_verified && renderUnverified()}
    </PlatformContext.Provider>
  );
}

export default PlatformContainer;
