/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Deleting from './deleting.view';

function DeletingContainer(props) {
  const {
    onCancel,
    onDeleted,
    user,
  } = props;

  const [submitting, setSubmitting] = useState(false);

  async function onConfirm() {
    setSubmitting(true);

    let success = false;
    try {
      await api.remove(`/${user.object}/${user.id}`);
      success = true;
    } catch (e) {
      // Silently ignore - `success` will be false so will show an error
    }

    setSubmitting(false);

    if (success) {
      onDeleted();
    } else {
      toast('Could not delete user. Something went wrong.');
    }
  }

  return (
    <Deleting
      onCancel={onCancel}
      onConfirm={onConfirm}
      submitting={submitting}
      user={user}
    />
  );
}

export default DeletingContainer;
