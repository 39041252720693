/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Field from 'common/input/field';

function Email(props) {
  const {
    email,
    error,
    onEmailChanged,
    onSubmit,
  } = props;

  return (
    <form onSubmit={onSubmit}>
      <Field
        className="py-5 px-6 text-lg text-gray-800"
        error={error}
        label="Email address"
        onChange={onEmailChanged}
        placeholder="name@example.com"
        value={email}
      />

      <Button className="mt-4 py-3 w-full" type="submit">Continue</Button>
    </form>
  );
}

export default Email;
