/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import useFileInput from 'helpers/hooks/use-file-input';

/* ------ View import ------ */
import ProfilePicture from './profile-picture.view';

function ProfilePictureContainer(props) {
  const {
    chatPlugin,
    onUpdated,
  } = props;

  const [uploadedImage, setUploadedImage] = useState(null);

  async function onImageUploaded(files) {
    const file = files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => setUploadedImage(reader.result));
    reader.readAsDataURL(file);
  }

  const onUploadImage = useFileInput(onImageUploaded, 'image/*');

  function onProfilePictureUpdated(profilePictureUrl) {
    onUpdated(profilePictureUrl);
    setUploadedImage(false);
  }

  return (
    <ProfilePicture
      chatPluginId={chatPlugin.id}
      onCancel={() => setUploadedImage(false)}
      onUpdated={onProfilePictureUpdated}
      onUploadImage={onUploadImage}
      profilePictureUrl={chatPlugin.profile_picture}
      uploadedImage={uploadedImage}
    />
  );
}

export default ProfilePictureContainer;
