/* ------ Helpers ------ */
import auth from 'helpers/auth';

const ACCOUNT_STORAGE_KEY = 'accounts';
const CURRENT_ACCOUNT_STORAGE_KEY = 'current_tenant';

function getAccounts() {
  let accounts = null;
  try {
    accounts = JSON.parse(localStorage.getItem(ACCOUNT_STORAGE_KEY));
  } catch (e) {
    // Silently ignore - will be set to empty array below
  }

  if (!Array.isArray(accounts)) {
    accounts = [];
  }

  return accounts;
}

function getAccount(id) {
  const accounts = getAccounts();

  let account = null;
  accounts.forEach(acc => {
    if (id === acc.id) {
      account = acc;
    }
  });

  return account;
}

function getCurrentAccount() {
  const accounts = getAccounts();

  const currentAccountId = localStorage.getItem(CURRENT_ACCOUNT_STORAGE_KEY);
  if (!currentAccountId) {
    return null;
  }

  let currentAccount = null;
  accounts.forEach(account => {
    if (account.id === currentAccountId) {
      currentAccount = account;
    }
  });

  return currentAccount;
}

/**
 * Important note:
 * Can only be 1 signed in account per business (ie. per
 * auth tenant id). Thus, if you pass an account to this function
 * from the same business as an existing account, it is overwritten.
 */
function addAccountAndSetCurrent(account) {
  let found = false;
  const accounts = getAccounts().map(acc => {
    if (acc.connect_account.id === account.connect_account.id) {
      found = true;
      return account;
    }

    return acc;
  });

  if (!found) {
    accounts.push(account);
  }

  localStorage.setItem(ACCOUNT_STORAGE_KEY, JSON.stringify(accounts));
  localStorage.setItem(CURRENT_ACCOUNT_STORAGE_KEY, account.id);
}

async function setCurrentAccount(account) {
  localStorage.setItem(CURRENT_ACCOUNT_STORAGE_KEY, account.id);
  await auth.init(account.connect_account.auth_tenant_id);
}

function removeCurrentAccount() {
  const account = getCurrentAccount();
  const accounts = getAccounts().filter(acc => acc.id !== account.id);
  if (accounts.length > 0) {
    setCurrentAccount(accounts[0]);
  } else {
    localStorage.setItem(CURRENT_ACCOUNT_STORAGE_KEY, null);
  }

  localStorage.setItem(ACCOUNT_STORAGE_KEY, JSON.stringify(accounts));
}

export default {
  addAccountAndSetCurrent,
  getCurrentAccount,
  getAccount,
  getAccounts,
  removeCurrentAccount,
  setCurrentAccount,
};
