/* ------ Module imports ------ */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import Business from './business.view';

function BusinessContainer() {
  const { id } = useParams();

  const [view, setView] = useState('loading');
  const [business, setBusiness] = useState(null);

  async function fetchBusiness() {
    let loadedBusiness = null;
    try {
      const { data } = (await api.get(`/business/${id}`)).data;
      loadedBusiness = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedBusiness) {
      setBusiness(loadedBusiness);
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchBusiness();
  });

  return (
    <Business
      business={business}
      view={view}
    />
  );
}

export default BusinessContainer;
