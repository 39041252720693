/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import SimpleLayout from 'common/layout/simple';
import PlainButton from 'common/plain-button';

function Unverified(props) {
  const {
    emailResent,
    onLogout,
    onResendEmail,
  } = props;

  return (
    <SimpleLayout>
      <div className="text-center">
        {!emailResent && (
          <>
            <p className="text-gray-800">
              A verification email has been sent to you.
              <br />
              Click the link in the email to verify your address and get started.
            </p>
            <p className="text-gray-800 mt-4 text-sm font-semibold">
              Note: It could take up to 5 minutes for you to receive the email.
              <br />
              If you haven&apos;t received it, check your junk/spam folder.
            </p>

            <div className="mt-12">
              <Button onClick={onResendEmail}>Re-send Email</Button>
            </div>
          </>
        )}

        {emailResent && (
          <p className="text-gray-800">
            A new email has been sent to you.
            <br />
            Please check your inbox.
          </p>
        )}

        <PlainButton
          className="mt-6"
          color="gray"
          onClick={onLogout}
        >
          Need to switch accounts? Click here to logout
        </PlainButton>
      </div>
    </SimpleLayout>
  );
}

export default Unverified;
