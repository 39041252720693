/* ------ Module imports ------ */
import React, { useContext, useState } from 'react';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import Home from './home.view';

function HomeContainer() {
  const { user } = useContext(PlatformContext);

  const [view, setView] = useState('loading');
  const [businesses, setBusinesses] = useState([]);

  async function fetchBusinesses() {
    let loadedBusinesses = null;
    try {
      const { data } = (await api.get('/business')).data;
      loadedBusinesses = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedBusinesses) {
      setBusinesses(loadedBusinesses);
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchBusinesses();
  });

  return (
    <Home
      businesses={businesses}
      name={user.connect_account.name}
      view={view}
    />
  );
}

export default HomeContainer;
