/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import PlainButton from 'common/plain-button';

function Resend(props) {
  const {
    onCancel,
    onConfirm,
    submitting,
  } = props;

  return (
    <div>
      <p className="text-sm text-gray-800 font-medium mb-1">
        Are you sure you want to resend this invitation?
      </p>

      <Button
        destructive
        loading={submitting}
        onClick={onConfirm}
      >
        Resend
      </Button>
      <PlainButton
        className="ml-4"
        color="gray"
        disabled={submitting}
        onClick={onCancel}
      >
        Cancel
      </PlainButton>
    </div>
  );
}

export default Resend;
