/* ------ Module imports ------ */
import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';

function Spacer(props) {
  const {
    index,
    isLastChild,
    onDragEnd,
  } = props;

  const ref = useRef(null);

  const [{ isOver }, drop] = useDrop({
    accept: 'category',
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return undefined;
      }

      if (!item.parent && (item.index === index || (item.index + 1) === index)) {
        return undefined;
      }

      const source = { index: item.index, droppableId: item.parent || 'parent', category: item.category };
      const destination = { index, droppableId: 'parent' };
      onDragEnd({ source, destination });

      return item;
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  drop(ref);

  return (
    <div className={`relative ${isLastChild ? 'h-20' : 'h-6'}`} ref={ref}>
      {isOver && <div className="absolute inset-0 mt-3 w-full h-px bg-blue-brand" />}
    </div>
  );
}

export default Spacer;
