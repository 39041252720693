/* ------ Module imports ------ */
import React from 'react';

function Checkbox(props) {
  const {
    checked,
    disabled,
    onSelect,
  } = props;

  function getClassName() {
    let boxClass = 'shadow h-4 w-4 bg-white flex items-center justify-center rounded border border-gray-200';

    if (checked) {
      boxClass += ' bg-blue-500 border-none';
    }
    if (checked && disabled) {
      boxClass += ' opacity-75';
    }
    if (disabled) {
      boxClass += ' cursor-not-allowed';
    }

    return boxClass;
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="flex cursor-pointer">
      <input
        disabled={disabled}
        onChange={e => onSelect(e.target.value)}
        className="hidden"
        type="checkbox"
        checked={checked || false}
      />

      <span className={getClassName()}>
        {checked && (
          <i
            className="ralli-icon-check text-white text-xs"
            style={{ fontSize: '10px' }}
          />
        )}
      </span>
    </label>
  );
}

export default Checkbox;
