/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import useFileInput from 'helpers/hooks/use-file-input';

/* ------ View import ------ */
import ProfilePicture from './profile-picture.view';

function ProfilePictureContainer(props) {
  const {
    onChange,
    profilePictureUrl,
  } = props;

  const [uploadedImage, setUploadedImage] = useState(null);

  async function onImageUploaded(files) {
    const file = files[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => setUploadedImage(reader.result));
    reader.readAsDataURL(file);
  }

  const onUploadImage = useFileInput(onImageUploaded, 'image/*');

  function onCropComplete(file) {
    onChange(file);
    setUploadedImage(false);
  }

  return (
    <ProfilePicture
      onCropComplete={onCropComplete}
      onUploadImage={onUploadImage}
      profilePictureUrl={profilePictureUrl}
      uploadedImage={uploadedImage}
    />
  );
}

export default ProfilePictureContainer;
