/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Local components ------ */
import Delete from './delete';
import Options from './options';

function User(props) {
  const {
    isCurrentUser,
    onDeleted,
    user,
  } = props;

  const [deleting, setDeleting] = useState(null);

  const name = `${user.first_name} ${user.last_name}`;

  return (
    <div className="border-b border-gray-200 last:border-b-0">
      <div className="flex items-center justify-between px-6 py-4">
        <div className="flex items-center">
          <img
            alt=""
            className="h-10 w-10 mr-4 rounded-lg"
            src={user.profile_picture}
          />

          <div className="flex items-center">
            <p className="font-semibold text-gray-800">{name}</p>
          </div>
        </div>

        {(!deleting && !isCurrentUser) && (
          <Options onDelete={() => setDeleting(true)} />
        )}
      </div>

      {deleting && (
        <Delete
          onCancel={() => setDeleting(false)}
          onDeleted={onDeleted}
          user={user}
        />
      )}
    </div>
  );
}

export default User;
