/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Input from 'common/input';
import PlainButton from 'common/plain-button';

function Add(props) {
  const {
    error,
    name,
    onCancel,
    onChange,
    onSubmit,
    submitting,
  } = props;

  return (
    <div className="bg-gray-100 border-b border-gray-200">
      <form
        className="p-6 pt-4"
        disabled={submitting}
        onSubmit={onSubmit}
      >
        <Input
          className="mb-6"
          disabled={submitting}
          error={error}
          label="Enter a name for the category set"
          onChange={onChange}
          value={name}
        />
        <div className="flex items-center">
          <Button
            loading={submitting}
            type="submit"
          >
            CREATE
          </Button>
          <PlainButton
            className="ml-6"
            color="gray"
            disabled={submitting}
            onClick={onCancel}
            type="button"
          >
            CANCEL
          </PlainButton>
        </div>
      </form>
    </div>
  );
}

export default Add;
