/* ------ Module imports ------ */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import CreateBusiness from './create-business.view';

function CreateBusinessContainer() {
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [name, setName] = useState('');

  async function onSubmit() {
    if (!name || !name.trim()) {
      setError(true);
      return;
    }

    setError(false);
    setSubmitting(true);

    const postData = { name };

    let createdBusiness = null;
    try {
      const { data } = (await api.post('/business', postData)).data;
      createdBusiness = data;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (createdBusiness) {
      history.push(`/business/${createdBusiness.id}`);
    } else {
      toast('Could not create business. Something went wrong.');
    }
  }

  return (
    <CreateBusiness
      error={error}
      name={name}
      onNameChanged={setName}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default CreateBusinessContainer;
