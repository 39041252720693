/* ------ Module imports ------ */
import React, { useRef, useState } from 'react';

/* ------ Helpers ------ */
import useOnClickOutside from 'helpers/hooks/use-on-click-outside';

/* ------ Common imports ------ */
import Icon from 'common/icon';

function Options(props) {
  const { onDelete } = props;

  const [expanded, setExpanded] = useState(false);

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setExpanded(false));

  return (
    <div ref={dropdownRef}>
      <button
        className="flex items-center justify-center ml-2 border border-gray-200 rounded-lg px-4 py-2 focus:outline-none hover:shadow"
        onClick={() => setExpanded(!expanded)}
        type="button"
      >
        <Icon className="text-sm text-gray-800" icon="more" />
      </button>

      {expanded && (
        <div className="relative">
          <div className="py-1 z-10 mt-3 popover popover-right w-48 absolute top-0 right-0 bg-white rounded-lg shadow-lg border border-gray-200">
            <button
              className="text-left w-full px-4 py-1 block hover:bg-gray-200 text-red-500 text-sm font-semibold focus:outline-none"
              onClick={onDelete}
              type="button"
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Options;
