/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View imports ------ */
import ForgottenPassword from './forgotten-password.view';

function ForgottenPasswordContainer(props) {
  const {
    business,
    email,
    onCancel,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  async function onSubmit() {
    setSubmitting(true);

    let resetSuccess = false;
    try {
      await api.post('/password_reset', { business: business.id, email }, false);
      resetSuccess = true;
    } catch (e) {
      toast('Something went wrong - please try again');
    }

    setSubmitting(false);

    if (resetSuccess) {
      setSuccess(true);
    } else {
      toast('Something went wrong - please try again');
    }
  }

  return (
    <ForgottenPassword
      email={email}
      onCancel={onCancel}
      onResend={() => setSuccess(false)}
      onSubmit={onSubmit}
      submitting={submitting}
      success={success}
    />
  );
}

export default ForgottenPasswordContainer;
