/* ------ Module imports ------ */
import firebase from 'firebase/app';
import 'firebase/auth';

/* ------ Config ------ */
import config from 'config';

let currentTenantId = null;

async function init(tenantId) {
  currentTenantId = tenantId;

  try {
    firebase.initializeApp(config.firebase, currentTenantId);
    await new Promise(resolve => {
      firebase.app(currentTenantId).auth().onAuthStateChanged(() => resolve());
    });
  } catch (e) {
    // Already initialized
  }

  firebase.app(currentTenantId).auth().tenantId = currentTenantId;
}

async function login(email, password, tenantId) {
  await init(tenantId);

  await firebase.app(tenantId).auth().signInWithEmailAndPassword(email, password);
}

function logout() {
  return firebase.app(currentTenantId).auth().signOut();
}

async function getToken() {
  const { currentUser } = firebase.app(currentTenantId).auth();
  if (!currentUser) {
    throw new Error();
  }

  const token = await currentUser.getIdToken(false);
  return token;
}

async function validatePassword(password) {
  const auth = firebase.app(currentTenantId).auth();
  if (!auth.currentUser) {
    throw new Error();
  }

  const credential = firebase.auth.EmailAuthProvider.credential(auth.currentUser.email, password);
  await auth.currentUser.reauthenticateAndRetrieveDataWithCredential(credential);
}

export default {
  init,
  getToken,
  login,
  logout,
  validatePassword,
};
