/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View imports ------ */
import Delete from './delete.view';

function DeleteContainer(props) {
  const {
    category,
    onCancel,
    onDeleted,
  } = props;

  const [submitting, setSubmitting] = useState(false);

  async function onDelete() {
    setSubmitting(true);

    let success = false;
    try {
      await api.remove(`/category/${category.id}`);
      success = true;
    } catch (e) {
      // Silently ignore.
    }

    setSubmitting(false);

    if (success) {
      onDeleted();
    } else {
      toast('Could not delete category. Something went wrong.');
    }
  }

  return (
    <Delete
      onCancel={onCancel}
      onConfirm={onDelete}
      submitting={submitting}
    />
  );
}

export default DeleteContainer;
