/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Card from 'common/card';

/* ------ Local components ------ */
import AddDomain from './add-domain';
import Domain from './domain';
import HelpSection from './help-section';

function Domains(props) {
  const {
    adding,
    chatPlugin,
    onAddDomain,
    onDomainCreated,
    onDomainDeleted,
  } = props;

  return (
    <Card className="mt-10 overflow-hidden">
      <Card.Title action={{ label: '+ Add new', onClick: () => onAddDomain(true) }}>Domains</Card.Title>
      {adding && (
        <AddDomain
          chatPlugin={chatPlugin}
          onCancel={() => onAddDomain(false)}
          onDomainCreated={onDomainCreated}
        />
      )}

      {chatPlugin.domains.length > 0 && (
        <div>
          {chatPlugin.domains.map(domain => (
            <Domain
              domain={domain}
              key={domain.id}
              onDomainDeleted={onDomainDeleted}
            />
          ))}
        </div>
      )}

      {chatPlugin.domains.length === 0 && (
        <div className="p-6">
          <p className="text-gray-600 text-sm text-center">You haven&apos;t set up any domains yet</p>
          <p className="mt-2 text-center text-sm text-green-600">If you choose not to add any domains the plugin will contine to function on any website.</p>
        </div>
      )}

      <HelpSection />
    </Card>
  );
}

export default Domains;
