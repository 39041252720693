/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';

/* ------ Local imports ------ */
import Category from './category';
import Spacer from './spacer';

function CategorySet(props) {
  const {
    categorySet,
    categories,
    dragDisabled,
    loadingCategory,
    onDone,
    onDragEnd,
  } = props;

  return (
    <>
      <div className="mb-10 flex justify-between">
        <h1 className="font-heading text-2xl font-medium text-gray-800">{categorySet.name}</h1>
        <Button onClick={onDone}>Done editing</Button>
      </div>
      <div>
        <Spacer
          index={0}
          onDragEnd={onDragEnd}
        />
        {categories.length > 0 && categories.map((category, i) => (
          <React.Fragment key={category.id}>
            <Category
              category={category}
              categorySet={categorySet}
              dragDisabled={dragDisabled}
              index={i}
              loadingCategory={loadingCategory}
              onDragEnd={onDragEnd}
            />
            <Spacer
              index={i + 1}
              isLastChild={i === categories.length - 1}
              onDragEnd={onDragEnd}
            />
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

export default CategorySet;
