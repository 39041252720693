/* ------ Module imports ------ */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Delete from './delete.view';

function DeleteContainer(props) {
  const { chatPlugin } = props;

  const history = useHistory();

  const [confirmValue, setConfirmValue] = useState('');
  const [view, setView] = useState('main');
  const [submitting, setSubmitting] = useState(false);

  async function onDelete() {
    setSubmitting(true);

    let success = false;
    try {
      await api.remove(`/chat_plugin/${chatPlugin.id}`);
      success = true;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (success) {
      history.push('/channels');
    } else {
      toast('Could not delete chat plugin. Something went wrong.');
    }
  }

  return (
    <Delete
      confirmValue={confirmValue}
      onCancel={() => setView('main')}
      onConfirmDelete={onDelete}
      onConfirmValueChanged={setConfirmValue}
      onStartDelete={() => setView('deleting')}
      submitting={submitting}
      view={view}
    />
  );
}

export default DeleteContainer;
