/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import EmailRequiredSetting from './email-required-setting.view';

function EmailRequiredSettingContainer(props) {
  const {
    chatPlugin,
    onChatPluginUpdated,
  } = props;

  const [emailSetting, setEmailSetting] = useState(chatPlugin.email_required);
  const [submitting, setSubmitting] = useState(false);

  async function onSave() {
    setSubmitting(true);

    let success = false;
    try {
      await api.patch(`/chat_plugin/${chatPlugin.id}`, { email_required: emailSetting });
      success = true;
    } catch (e) {
      // Silently ignore
    }

    setSubmitting(false);

    if (success) {
      toast('Settings saved successfully');
      onChatPluginUpdated({ ...chatPlugin, email_required: emailSetting });
    } else {
      toast('Could not update settings. Something went wrong.');
    }
  }

  return (
    <EmailRequiredSetting
      chatPlugin={chatPlugin}
      emailSetting={emailSetting}
      onEmailSettingChanged={setEmailSetting}
      onSave={onSave}
      submitting={submitting}
    />
  );
}

export default EmailRequiredSettingContainer;
