/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import Users from './users.view';

function UsersContainer(props) {
  const { business } = props;

  const [activeUsers, setActiveUsers] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [showInvite, setShowInvite] = useState(false);

  const [tab, setTab] = useState('active');
  const [view, setView] = useState('loading');

  async function fetchInvites() {
    const { data } = (await api.get(`/invite?business=${business.id}`)).data;
    return data;
  }

  async function fetchUsers() {
    const { data } = (await api.get(`/user?business=${business.id}`)).data;
    return data;
  }

  async function fetchData() {
    let loadedInvites = null;
    let loadedUsers = null;
    try {
      [loadedInvites, loadedUsers] = await Promise.all([
        fetchInvites(),
        fetchUsers(),
      ]);
    } catch (e) {
      // Silently ignore - users or invites will be null so will show an error
    }

    if (loadedUsers && loadedInvites) {
      setActiveUsers(loadedUsers.filter(u => u.type === 'user' || u.type === 'admin'));
      setInvitedUsers(loadedInvites);

      setView('main');
      setTab('active');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchData();
  });

  function onInviteSent(newInvite) {
    const updatedInvitedUsers = invitedUsers.concat(newInvite);
    setInvitedUsers(updatedInvitedUsers);
  }

  function onUserDeleted(deletedUser) {
    if (deletedUser.object === 'user') {
      const updatedUsers = activeUsers.filter(u => u.id !== deletedUser.id);
      setActiveUsers(updatedUsers);
    }
    if (deletedUser.object === 'invite') {
      const updatedInvites = invitedUsers.filter(u => u.id !== deletedUser.id);
      setInvitedUsers(updatedInvites);

      if (updatedInvites.length === 0) {
        setTab('active');
      }
    }
  }

  function onUserUpdated(updatedUser) {
    const updatedUsers = activeUsers.map(u => {
      if (u.id === updatedUser.id) {
        return updatedUser;
      }

      return u;
    });

    setActiveUsers(updatedUsers);
  }

  return (
    <Users
      activeUsers={activeUsers}
      businessId={business.id}
      invitedUsers={invitedUsers}
      onChangeTab={setTab}
      onCloseInvite={() => setShowInvite(false)}
      onShowInvite={() => setShowInvite(true)}
      onInviteSent={onInviteSent}
      onUserDeleted={onUserDeleted}
      onUserUpdated={onUserUpdated}
      showInvite={showInvite}
      tab={tab}
      view={view}
    />
  );
}

export default UsersContainer;
