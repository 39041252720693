/* ------ Module imports ------ */
import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

/* ------ Common imports ------ */
import Icon from 'common/icon';
import Loading from 'common/loading';
import User from 'common/user';

function Category(props) {
  const {
    category,
    categorySet,
    dragDisabled,
    index,
    loadingCategory,
    onDragEnd,
    parent,
  } = props;

  const ref = useRef(null);
  const hoverArea = useRef(null);

  const [expanded, setExpanded] = useState(false);
  const [hoverAreaState, setHoverAreaState] = useState(null);

  const hasChildren = category.children && category.children.length > 0;
  const hasUsers = (category.users && category.users.length > 0)
    || (category.invites && category.invites.length > 0);

  let canDropInside = false;
  if (!expanded && (hasChildren || !hasUsers)) {
    canDropInside = true;
  }

  useEffect(() => {
    if (!category.children || category.children.length === 0) {
      setExpanded(false);
    }
  }, [category.children]);

  const [, drop] = useDrop({
    accept: 'category',
    drop: (item, monitor) => {
      /**
       * This function handles updating the tree when a category is dropped.
       *
       * Note that a lot of the logic of this function is duplicated from the
       * hover function below, so see the explanations there of what is happening
       * with position calculations. Anything extra will be explained here.
       */

      /**
       * If this category is a parent and a category was dropped
       * on a child, the event bubbles up the chain. The `didDrop` function
       * returns true if this drop event has already been handled (ie. by the
       * child) so we check it here, and bail out if it has already been handled.
       */
      if (monitor.didDrop()) {
        return;
      }

      let newIndex = null;
      let droppableId = parent || 'parent';

      /**
       * This is some special handling for top-level categories. Because we use
       * spacer components to indicate dropping above or below a top-level category,
       * then the only possible drop handler for these top level categories is to drop
       * it inside. That only applies though, when a top-level category is not
       * expanded, because if it's expanded you'll be dropping inside the category anyway.
       *
       * Note we give newIndex as 1 when we want to just drop inside a category, so that
       * it gets added as the first child of the parent.
       *
       * Also, note that the droppableId refers to the parent category that we are dropping into.
       */
      if (!parent) {
        if (expanded) {
          return;
        }

        newIndex = 0;
        droppableId = category.id;
      } else {
        /**
         * These calculations compute where to insert the new category based
         * on where on this category it was dropped. See the hover function
         * below for a more in-depth explanation of what's going on here.
         */
        const hoverBoundingRect = ref.current.getBoundingClientRect();

        let hoverDividingPoint;
        if (canDropInside) {
          hoverDividingPoint = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 3;
        } else {
          hoverDividingPoint = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        }

        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (hoverClientY < hoverDividingPoint) {
          newIndex = index;
        } else if (!expanded) {
          if (hoverClientY > (2 * hoverDividingPoint)) {
            newIndex = index + 1;
          } else {
            newIndex = 0;
            droppableId = category.id;
          }
        }
      }

      /**
       * If we have calculated a new index, then we build source
       * and destination objects, and pass them up to the onDragEnd
       * handler to complete the move.
       */
      if (newIndex !== null) {
        const source = { index: item.index, droppableId: item.parent || 'parent', category: item.category };
        const destination = { index: newIndex, droppableId };
        onDragEnd({ source, destination });
      }
    },
    hover: (item, monitor) => {
      /**
       * A little explanation is warranted about how the `hoverArea` works, and why it is stored in
       * both state and a ref.
       *
       * So the state should be fairly obvious - when we hover certain parts of a category, we store
       * the state of where we should be showing the little hover indicator.
       *
       * The ref is a litte more complex and it's to do with how react works with this hook. If we
       * just set the state and didn't do anything with this ref, then when we checked the state
       * again in this function (eg. if (hoverAreaState !== null)) then we would have the old value
       * of the state still so it wouldn't be accurate. Thus, we have this hoverArea ref which gets
       * set whenever we update state, so that when we check it again on a rerender, it is accurate.
       */

      /**
       * If we aren't hovering this element, ensure that the hover state is set back to null.
       */
      if (!ref.current || !monitor.isOver({ shallow: true })) {
        if (hoverArea.current !== null) {
          hoverArea.current = null;
          setHoverAreaState(null);
          return;
        }
      }

      /**
       * This is some special handling for top-level categories. Because we use
       * spacer components to indicate hovering above or below a top-level category,
       * then the only possible hover state for the category is middle. Of course,
       * that is only applied when a root level category is not expanded because if it's
       * expanded you'll be dropping inside the category anyway.
       */
      if (!parent) {
        if (!expanded) {
          hoverArea.current = 'middle';
          setHoverAreaState('middle');
        }

        return;
      }

      /**
       * This next section calculates the y-axis division point of the category
       * for showing different hover states. Let's break down what this means a bit.
       *
       * If the category can't be dropped inside (ie. the category has users on it),
       * that means other categories can only be dropped either above or below it.
       * Thus, the dividing point is the middle of the category bounding
       * box - hovering above the middle highlights the top and hovering below
       * the middle highlights the bottom.
       *
       * If the category has children, that means you could also drop on the middle of the
       * category to add this category as a child. Thus, the dividing point is a third
       * of the height of the bounding box - hovering the top third highlights the top,
       * hovering the middle highlights the whole box to drop inside, and hovering the lower
       * third highlights the bottom.
       */
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      let hoverDividingPoint;
      if (canDropInside) {
        hoverDividingPoint = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 3;
      } else {
        hoverDividingPoint = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      }

      /**
       * Following on from the explanation above, this section is calculating the
       * current y-position of mouse/finger on the box, and then noting which area
       * to highlight based on that.
       *
       * Note that the middle and bottom highlights are only triggered if this
       * category is not currently expanded. Otherwise the added height of being
       * expanded would break the calculation.
       */
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      let result = null;
      if (hoverClientY < hoverDividingPoint) {
        result = 'top';
      } else if (!expanded) {
        if (hoverClientY > (2 * hoverDividingPoint)) {
          result = 'bottom';
        } else {
          result = 'middle';
        }
      }

      /**
       * Now that we have calculated what should be highlighted, we just update
       * the state and the current ref (note: see the explanation at the top of
       * this function to learn more about the state and ref usage).
       *
       * Updating the state triggers a rerender to finally show the hover highlight
       * to the user!
       */
      if (result === 'top' && hoverArea.current !== 'top') {
        hoverArea.current = 'top';
        setHoverAreaState('top');
      }
      if (result === 'bottom' && hoverArea.current !== 'bottom') {
        hoverArea.current = 'bottom';
        setHoverAreaState('bottom');
      }
      if (result === 'middle' && hoverArea.current !== 'middle' && item.category.id !== category.id) {
        hoverArea.current = 'middle';
        setHoverAreaState('middle');
      }
      if (result === null && hoverArea.current !== null) {
        hoverArea.current = null;
        setHoverAreaState(null);
      }
    },
    collect: monitor => {
      /**
       * This is a little bit of a hack because we aren't using `collect` how it's
       * intended to be used.
       *
       * Instead, because collect runs on every render, we use it to check if
       * this category is no longer being hovered, and in that case, set the
       * hover area back to null.
       */
      if (hoverArea.current !== null && !monitor.isOver({ shallow: true })) {
        hoverArea.current = null;
        setHoverAreaState(null);
      }
    },
  });

  const [, drag, preview] = useDrag({
    item: {
      type: 'category',
      index,
      category,
      parent,
    },
  });

  drop(ref);

  let borderColor = 'border-gray-200';
  if (hoverAreaState === 'top') {
    borderColor = 'border-blue-brand';
  } else if (!parent) {
    borderColor = 'border-transparent';
  }

  function renderExpanded() {
    if (category.children && category.children.length > 0) {
      return category.children.map((child, i) => (
        <div
          className={`${parent ? '' : 'ml-6'}`}
          key={child.id}
        >
          <Category
            category={child}
            categorySet={categorySet}
            dragDisabled={dragDisabled}
            index={i}
            loadingCategory={loadingCategory}
            onDragEnd={onDragEnd}
            parent={category.id}
          />
        </div>
      ));
    }

    if (
      (category.users && category.users.length > 0)
        || (category.invites && category.invites.length > 0)
    ) {
      return (
        <div className="ml-6 border-t border-gray-200">
          <p className="text-gray-600 text-xs uppercase font-medium mt-4 mb-2">Users</p>
          {category.users.map(user => <User key={user.id} user={user} />)}
          {category.invites.map(invite => <User key={invite.id} user={invite} />)}
        </div>
      );
    }

    return (
      <div className="ml-6 border-t border-gray-200 py-4">
        <p className="text-sm text-gray-800">Empty category</p>
        <p className="mt-1 text-xs text-gray-600">No subcategories or users</p>
      </div>
    );
  }

  return (
    <div ref={preview}>
      <div className="flex items-start">
        <div className='flex items-center w-8 mt-4'>
          {loadingCategory && loadingCategory === category.id && (
            <Loading xSmall />
          )}

          {(!loadingCategory || loadingCategory !== category.id) && (
            <div className="flex items-center rounded hover:bg-gray-300 px-1 py-1" ref={drag}>
              <Icon className="text-gray-600 mr-1" icon="more" fontSize={12} />
              <Icon className="text-gray-600" icon="more" fontSize={12} />
            </div>
          )}
        </div>

        <div className={`w-full bg-white ${parent ? '' : 'shadow-lg rounded-lg'}`}>
          <div className={`border-t w-full h-full ${borderColor}`} ref={ref}>
            <div className={`border-b ${(hoverAreaState === 'bottom') ? 'border-blue-brand' : 'border-transparent'} ${hoverAreaState === 'middle' ? 'bg-blue-100' : ''}`}>
              <div className={`pr-6 py-4 ${parent ? '' : 'pl-6'}`}>
                <button
                  className="flex items-center font-semibold text-sm focus:outline-none"
                  onClick={() => setExpanded(!expanded)}
                  type="button"
                >
                  <Icon
                    className={`text-xs ${expanded ? 'text-blue-brand' : 'text-gray-600'}`}
                    icon={expanded ? 'down-chevron' : 'right-chevron'}
                  />
                  <span className="text-gray-800 ml-4">{category.name}</span>
                </button>
              </div>
            </div>
          </div>

          {expanded && renderExpanded()}
        </div>
      </div>
    </div>
  );
}

export default Category;
