/* ------ Helpers ------ */
import api from 'helpers/api';
import accounts from 'helpers/accounts';

/* ------ Local imports ------ */
import userCache from './cache';

let handlers = [];

function removeHandler(fn) {
  return () => {
    handlers = handlers.filter(handler => handler !== fn);
  };
}

function registerHandler(fn) {
  handlers.push(fn);
  return removeHandler(fn);
}

function updateUser(user) {
  userCache.setUser(user);
  handlers.forEach(fn => fn(user));
  return user;
}

async function refreshUser() {
  let loadedUser = null;
  try {
    const { data } = (await api.get('/connect_account_user/me')).data;
    loadedUser = data;
  } catch (e) {
    // Silently ignore - loadedUser will still be null so we will end up returning null
  }

  if (loadedUser) {
    updateUser(loadedUser);
    accounts.addAccountAndSetCurrent(loadedUser);

    return loadedUser;
  }

  return null;
}

async function getCurrentUser() {
  const user = userCache.getUser();
  if (user) {
    return user;
  }

  const loadedUser = await refreshUser();
  return loadedUser;
}

export default {
  getCurrentUser,
  refreshUser,
  registerHandler,
};
