/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Common imports ------ */
import Card from 'common/card';

/* ------ View imports ------ */
import Edit from './edit';
import View from './view';

function Details(props) {
  const {
    chatPlugin,
    onChatPluginUpdated,
  } = props;

  const [view, setView] = useState('view');

  function onPluginUpdated(updatedPlugin) {
    onChatPluginUpdated(updatedPlugin);
    setView('view');
  }

  let inner = null;
  if (view === 'edit') {
    inner = (
      <Edit
        chatPlugin={chatPlugin}
        onCancel={() => setView('view')}
        onChatPluginUpdated={onPluginUpdated}
      />
    );
  } else {
    inner = (
      <View
        chatPlugin={chatPlugin}
        onEdit={() => setView('edit')}
      />
    );
  }

  return (
    <Card className="mt-10">
      <Card.Title
        action={view === 'view' ? { label: 'Edit', onClick: () => setView('edit') } : null}
      >
        Details
      </Card.Title>

      {inner}
    </Card>
  );
}

export default Details;
