/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import Accounts from './accounts.view';

function AccountsContainer(props) {
  const {
    email,
    onBack,
    onSubmit,
  } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [accounts, setAccounts] = useState(null);

  async function fetchAccounts() {
    let loadedAccounts = null;
    try {
      const { data } = (await api.get(`/connect_account?email=${email}`, false)).data;
      loadedAccounts = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedAccounts) {
      setAccounts(loadedAccounts);
    } else {
      setError(true);
    }

    setLoading(false);
  }

  useDidMount(() => {
    fetchAccounts();
  });

  return (
    <Accounts
      accounts={accounts}
      error={error}
      loading={loading}
      onBack={onBack}
      onSelectAccount={onSubmit}
    />
  );
}

export default AccountsContainer;
