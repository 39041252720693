/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helper imports ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View imports ------ */
import AddCategory from './add-category.view';

function AddCategoryContainer(props) {
  const {
    categorySetId,
    onCategoryAdded,
  } = props;

  const [adding, setAdding] = useState(false);
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit(ev) {
    ev.preventDefault();

    if (!name || !name.trim()) {
      setError(true);
      return;
    }

    setError(false);
    setSubmitting(true);

    let category = null;

    const postData = { name, category_set: categorySetId };
    try {
      const { data } = (await api.post('/category', postData)).data;
      category = data;
    } catch (e) {
      // Silently ignore.
    }

    setSubmitting(false);

    if (category) {
      onCategoryAdded(category);
      setAdding(false);
      setName('');
    } else {
      toast('Something went wrong. Please try again.');
    }
  }

  return (
    <AddCategory
      adding={adding}
      error={error}
      name={name}
      onAdd={() => setAdding(true)}
      onCancel={() => setAdding(false)}
      onNameChanged={setName}
      onSubmit={onSubmit}
      submitting={submitting}
    />
  );
}

export default AddCategoryContainer;
