/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Card from 'common/card';
import Loading from 'common/loading';

/* ------ Local components ------ */
import ApiKey from './api-key';
import NewKey from './new-key';

function ApiKeys(props) {
  const {
    apiKeys,
    creatingKey,
    onCancelCreatingKey,
    onCreateKey,
    onKeyCreated,
    onKeyDeleted,
    view,
  } = props;

  function renderError() {
    // TODO
    return null;
  }

  function renderKeys() {
    if (apiKeys.length === 0) {
      return (
        <p className="text-gray-800 px-6 py-4">
          You don&apos;t have any api keys
        </p>
      );
    }

    return apiKeys.map(key => (
      <ApiKey
        apiKey={key}
        key={key.id}
        onDeleted={() => onKeyDeleted(key)}
      />
    ));
  }

  function renderMain() {
    return (
      <>
        {creatingKey && (
          <NewKey
            onClose={onCancelCreatingKey}
            onKeyCreated={onKeyCreated}
          />
        )}
        {renderKeys()}
      </>
    );
  }

  return (
    <Card className="mt-10">
      <Card.Title action={{ label: '+ Create new key', onClick: onCreateKey }}>API Keys</Card.Title>
      {view === 'loading' && <Loading />}
      {view === 'error' && renderError()}
      {view === 'main' && renderMain()}
    </Card>
  );
}

export default ApiKeys;
