import { createBrowserHistory } from 'history';

const historyStack = [];
const history = createBrowserHistory();

history.listen((location, action) => {
  if (action === 'PUSH') {
    historyStack.push(location);
    window.scrollTo(0, 0);
  } else if (action === 'POP') {
    historyStack.pop();
  } else if (action === 'REPLACE') {
    historyStack.pop();
    historyStack.push();
  }
});

const stack = () => JSON.parse(JSON.stringify(historyStack));

export { stack };
export default history;
