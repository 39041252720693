/* ------ Module imports ------ */
import React from 'react';

function View(props) {
  const { chatPlugin } = props;

  return (
    <div className="p-6">
      <div className="flex items-center">
        <p className="text-sm font-semibold w-40 text-gray-800">Name</p>
        <p className="text-sm text-gray-800">{chatPlugin.name}</p>
      </div>
      <div className="flex mt-4">
        <p className="text-sm flex-shrink-0 font-semibold w-40 text-gray-800">Greeting</p>
        <p className="text-sm text-gray-800 break-all">{chatPlugin.greeting}</p>
      </div>
      <div className="flex mt-4">
        <p className="text-sm flex-shrink-0 font-semibold w-40 text-gray-800">Description</p>
        <p className="text-sm text-gray-800 break-all">{chatPlugin.description}</p>
      </div>
    </div>
  );
}

export default View;
