/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import ChatPlugins from './chat-plugins.view';

function ChatPluginsContainer(props) {
  const { business } = props;

  const [view, setView] = useState('loading');
  const [plugins, setPlugins] = useState(null);

  async function fetchData() {
    let loadedPlugins = null;
    try {
      const { data } = (await api.get(`/chat_plugin?business=${business.id}`)).data;
      loadedPlugins = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedPlugins) {
      setPlugins(loadedPlugins);
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchData();
  });

  return (
    <ChatPlugins
      businessId={business.id}
      plugins={plugins}
      view={view}
    />
  );
}

export default ChatPluginsContainer;
