/* ------ Module imports ------ */
import React from 'react';

function LoadingSpinner(props) {
  const {
    small,
    white,
  } = props;

  function buildClassName() {
    const className = ['loading-spinner'];
    if (white) {
      className.push('loading-spinner-white');
    }

    return className.join(' ');
  }

  const size = small
    ? '18px'
    : '40px';

  return (
    <svg
      className={buildClassName()}
      width={size}
      height={size}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="loading-spinner-path"
        cx="33"
        cy="33"
        fill="none"
        r="30"
        strokeLinecap="round"
        strokeWidth="6"
      />
    </svg>
  );
}

export default LoadingSpinner;
