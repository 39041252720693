/* ------ Module imports ------ */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

/* ------ Helpers ------ */
import api from 'helpers/api';
import history from 'helpers/history';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ Common imports ------ */
import Loading from 'common/loading';

/* ------ View import ------ */
import VerifyEmailChange from './verify-email-change.view';

function VerifyEmailChangeContainer() {
  const { token } = useParams();

  const [errorCode, setErrorCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);

  async function verify() {
    let success = false;
    try {
      await api.post(`/email_change/${token}`, null, false);
      success = true;
    } catch (e) {
      if (e && e.response && e.response.data) {
        setErrorCode(e.response.data.code);
      }
    }

    if (success) {
      setVerified(true);
    }

    setLoading(false);
  }

  useDidMount(() => {
    verify();
  });

  function onContinue() {
    window.location = '/';
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <VerifyEmailChange
      errorCode={errorCode}
      onContinue={onContinue}
      onGoBack={() => history.push('/profile')}
      verified={verified}
    />
  );
}

export default VerifyEmailChangeContainer;
