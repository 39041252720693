/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ View import ------ */
import Header from './header.view';

function HeaderContainer(props) {
  const {
    category,
    deleting,
    depth,
    expanded,
    hasParent,
    onAddSubcategory,
    onCategoryUpdated,
    onDelete,
    onToggleExpanded,
  } = props;

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [renaming, setRenaming] = useState(false);

  function onShowAddSubcategory() {
    setPopoverVisible(false);
    onAddSubcategory();
  }

  function onRename() {
    setPopoverVisible(false);
    setRenaming(true);
  }

  function onShowDelete() {
    setPopoverVisible(false);
    onDelete();
  }

  function onRenamed(name) {
    const updatedCategory = category;
    updatedCategory.name = name;
    onCategoryUpdated(updatedCategory);
    setRenaming(false);
  }

  return (
    <Header
      category={category}
      deleting={deleting}
      depth={depth}
      expanded={expanded}
      hasParent={hasParent}
      onAddSubcategory={onShowAddSubcategory}
      onCancelRenaming={() => setRenaming(false)}
      onDelete={onShowDelete}
      onRename={onRename}
      onRenamed={onRenamed}
      onToggleExpanded={onToggleExpanded}
      onTogglePopoverVisible={() => setPopoverVisible(!popoverVisible)}
      popoverVisible={popoverVisible}
      renaming={renaming}
    />
  );
}

export default HeaderContainer;
