/* ------ Module imports ------ */
import React, { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';

/* ------ Context ------ */
import PlatformContext from 'components/platform/context';

/* ------ Helpers ------ */
import useOnClickOutside from 'helpers/hooks/use-on-click-outside';

function Popover(props) {
  const {
    onClose,
    onLogout,
  } = props;

  const { user } = useContext(PlatformContext);

  const ref = useRef();

  useOnClickOutside(ref, onClose);

  return (
    <div
      className="z-50 w-56 mr-4 mt-2 absolute top-0 right-0 popover popover-right bg-white shadow-xl border border-gray-200 rounded-lg"
      ref={ref}
    >
      <div className="px-4 py-3 border-b border-gray-200">
        <p className="text-gray-800 text-sm font-semibold truncate">{`${user.first_name} ${user.last_name}`}</p>
        <p className="text-gray-800 text-sm mt-1 truncate">{user.connect_account.name}</p>
      </div>

      <div className="py-1">
        <Link
          className="text-left w-full px-4 py-1 block hover:bg-gray-200 text-blue-500 text-sm font-semibold"
          onClick={onClose}
          to="/profile"
        >
          Profile
        </Link>
        <button
          className="text-left w-full px-4 py-1 block hover:bg-gray-200 text-yellow-500 text-sm font-semibold"
          onClick={onLogout}
          type="button"
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default Popover;
