/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import CategorySets from './category-sets.view';

function CategorySetsContainer(props) {
  const { business } = props;

  const [view, setView] = useState('loading');
  const [categorySets, setCategorySets] = useState(null);
  const [adding, setAdding] = useState(false);

  async function fetchData() {
    let loadedSets = null;
    try {
      const { data } = (await api.get(`/category_set?business=${business.id}&expand[]=in_use_by`)).data;
      loadedSets = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedSets) {
      setCategorySets(loadedSets);
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchData();
  });

  return (
    <CategorySets
      adding={adding}
      businessId={business.id}
      categorySets={categorySets}
      onAdd={() => setAdding(true)}
      onCancelAdding={() => setAdding(false)}
      view={view}
    />
  );
}

export default CategorySetsContainer;
