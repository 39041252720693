/* ------ Module imports ------ */
import { useCallback, useEffect, useRef } from 'react';

function useFileInput(callback, accept) {
  const input = useRef();

  useEffect(() => {
    input.current = document.createElement('input');
    input.current.type = 'file';
    if (accept) {
      input.current.accept = accept;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (input.current) {
      input.current.onchange = () => {
        if (input.current && input.current.files) {
          callback(input.current.files);
        }
      };
    }
  }, [callback]);

  return useCallback(() => {
    if (input.current) {
      input.current.value = '';
      input.current.click();
    }
  }, []);
}

export default useFileInput;
