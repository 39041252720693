/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import accounts from 'helpers/accounts';
import api from 'helpers/api';
import auth from 'helpers/auth';
import history from 'helpers/history';

/* ------ View import ------ */
import Unverified from './unverified.view';

function UnverifiedContainer() {
  const [emailResent, setEmailResent] = useState(false);

  async function onResendEmail() {
    await api.post('/email_verification');
    setEmailResent(true);
  }

  function onLogout() {
    accounts.removeCurrentAccount();
    auth.logout();
    history.push('/login');
  }

  return (
    <Unverified
      emailResent={emailResent}
      onLogout={onLogout}
      onResendEmail={onResendEmail}
    />
  );
}

export default UnverifiedContainer;
