/* ------ Module imports ------ */
import React from 'react';

function Title(props) {
  const { children } = props;

  return (
    <h1 className="font-heading text-2xl font-medium text-gray-800">{children}</h1>
  );
}

export default Title;
