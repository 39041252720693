/* ------ Module imports ------ */
import React from 'react';

function Field(props) {
  const {
    className,
    disabled,
    onChange,
    placeholder,
    suffix,
    type,
    value,
  } = props;

  const baseClassName = 'w-full py-2 px-3 text-sm outline-none flex-1';
  return (
    <div className="flex border border-gray-300 rounded-lg focus-within:border-blue-500 overflow-hidden">
      <input
        className={`${baseClassName} ${className}`}
        disabled={disabled}
        onChange={e => onChange(e.target.value)}
        type={type || 'text'}
        placeholder={placeholder}
        value={value}
      />
      {suffix && <div className="py-2 px-6 text-sm rounded-r-lg text-gray-600 border-l border-inherit">{suffix}</div>}
    </div>
  );
}

export default Field;
