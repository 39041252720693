/* ------ Module imports ------ */
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';

/* ------ Helpers ------ */
import history from 'helpers/history';

/* ------ Route imports ------ */
import AcceptInvite from 'components/accept-invite';
import Login from 'components/login';
import Platform from 'components/platform';
import ResetPassword from 'components/reset-password';
import VerifyEmail from 'components/verify-email';
import VerifyEmailChange from 'components/verify-email-change';

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/verify-email/:token" component={VerifyEmail} />
        <Route path="/verify-email-change/:token" component={VerifyEmailChange} />

        <Route path="/accept-invite/:token" component={AcceptInvite} />

        <Route path="/reset-password/:token" component={ResetPassword} />

        <Route path="/login" component={Login} />

        <Route path="*" component={Platform} />
      </Switch>
    </Router>
  );
}

export default App;
