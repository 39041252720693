/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import SimpleLayout from 'common/layout/simple';

function VerifyEmail(props) {
  const {
    errorCode,
    onContinue,
    verified,
  } = props;

  function getText() {
    if (!verified) {
      if (errorCode === 'expired') {
        return 'The link you used to verify your email has expired. You will need to login again to get a new email verification link.';
      }

      return 'There was an error verifiying your email address.';
    }

    return 'Thanks! Your email has been verified.';
  }

  return (
    <SimpleLayout>
      <div className="text-center">
        <p className="text-gray-800">{getText()}</p>

        {verified && (
          <div className="mt-10">
            <Button onClick={onContinue}>Continue to Ralli</Button>
          </div>
        )}
      </div>
    </SimpleLayout>
  );
}

export default VerifyEmail;
