/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Common imports ------ */
import Icon from 'common/icon';

function HelpSection() {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <div className={`border-t border-gray-200 px-6 ${showHelp ? 'py-6' : 'py-4'}`}>
      {showHelp && (
        <div className="mb-6">
          <p className="font-semibold text-sm mb-2 text-gray-800">What are domains for?</p>
          <p className="text-sm text-gray-700">
            If you do not add any domains, the chat plugin is able to be used on any website.
            If you would prefer it to only work on specific websites, you can enter their domains
            here.
            <br />
            Note that you only need to add the domain like&nbsp;
            <span className="text-xs bg-gray-200 px-2 py-1 rounded font-mono">ralli.app/</span>
            . We don&apos;t need you to add all your pages that appear after the&nbsp;
            <span className="text-xs bg-gray-200 px-2 py-1 rounded font-mono">/</span>
            .
          </p>
        </div>
      )}

      <button
        className="flex items-center text-gray-800 font-semibold text-xs"
        onClick={() => setShowHelp(!showHelp)}
        type="button"
      >
        <Icon
          fontSize="8px"
          icon={showHelp ? 'up-chevron' : 'down-chevron'}
        />

        <span className="ml-2">{showHelp ? 'Hide help' : 'Learn more about domains'}</span>
      </button>
    </div>
  );
}

export default HelpSection;
