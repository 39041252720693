/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import NewWebhook from './new-webhook.view';

function NewWebhookContainer(props) {
  const {
    onClose,
    onWebhookCreated,
  } = props;

  const [url, setUrl] = useState('');
  const [events, setEvents] = useState({ conversations: true, timelineItems: true });
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();

    if (!url || !url.trim()) {
      setError(true);
      return;
    }

    setError(false);

    if (!events.conversations && events.timelineItems) {
      toast('You must select at least 1 event to subscribe to');
      return;
    }

    setSubmitting(true);

    const eventsArray = [];
    if (events.conversations) {
      eventsArray.push('conversation.created');
    }
    if (events.timelineItems) {
      eventsArray.push('timeline_item.created');
    }

    const postData = {
      url,
      events: eventsArray,
    };

    let webhook = null;
    try {
      const { data } = (await api.post('/webhook', postData)).data;
      webhook = data;
    } catch (err) {
      // Silently ignore
    }

    setSubmitting(false);

    if (!webhook) {
      toast('Could not create webhook. Something went wrong.');
      setSubmitting(false);
      return;
    }

    onWebhookCreated(webhook);
    onClose();
  }

  function onToggleEvent(key) {
    if (key !== 'conversations' && key !== 'timelineItems') {
      return;
    }

    const newEvents = { ...events };
    newEvents[key] = !newEvents[key];
    setEvents(newEvents);
  }

  return (
    <NewWebhook
      error={error}
      events={events}
      onClose={onClose}
      onToggleEvent={onToggleEvent}
      onSubmit={onSubmit}
      onUrlChange={setUrl}
      submitting={submitting}
      url={url}
    />
  );
}

export default NewWebhookContainer;
