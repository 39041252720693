/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Loading from 'common/loading';

/* ------ Local components ------ */
import CategorySets from './category-sets';
import ChatPlugins from './chat-plugins';
import Users from './users';

function Business(props) {
  const {
    business,
    view,
  } = props;

  if (view === 'loading') {
    return <Loading />;
  }

  if (view === 'error') {
    // TODO
    return null;
  }

  return (
    <>
      <h1 className="font-heading text-2xl font-medium text-gray-800 mb-10">{business.name}</h1>
      <ChatPlugins business={business} />
      <CategorySets business={business} />
      <Users business={business} />
    </>
  );
}

export default Business;
