/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import toast from 'helpers/toast';

/* ------ View import ------ */
import Settings from './settings.view';

function SettingsContainer(props) {
  const {
    onDeleted,
    onUserUpdated,
    totalUsers,
    user,
  } = props;

  const [view, setView] = useState('main');
  const [updating, setUpdating] = useState(false);

  async function onToggleAdmin() {
    setUpdating(true);

    const newType = user.type === 'admin'
      ? 'user'
      : 'admin';

    let success = false;
    try {
      await api.patch(`/user/${user.id}`, { type: newType });
      success = true;
    } catch (e) {
      toast('Something went wrong please try again');
    }

    if (success) {
      const updatedUser = user;
      updatedUser.type = newType;
      onUserUpdated(updatedUser);
    }

    setUpdating(false);
  }

  return (
    <Settings
      onCancel={() => setView('main')}
      onDeleted={onDeleted}
      onShowDeleting={() => setView('deleting')}
      onShowResending={() => setView('resending')}
      onToggleAdmin={onToggleAdmin}
      totalUsers={totalUsers}
      updating={updating}
      user={user}
      view={view}
    />
  );
}

export default SettingsContainer;
