/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ View import ------ */
import Email from './email.view';

function EmailContainer(props) {
  const { onSubmit } = props;

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  async function onSubmitForm(e) {
    e.preventDefault();

    setError(false);

    if (email) {
      onSubmit(email);
    } else {
      setError(true);
    }
  }

  return (
    <Email
      email={email}
      error={error}
      onEmailChanged={setEmail}
      onSubmit={onSubmitForm}
    />
  );
}

export default EmailContainer;
