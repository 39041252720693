/* ------ Module imports ------ */
import React from 'react';

function Bubble(props) {
  const {
    backgroundColor,
    foregroundColor,
  } = props;

  return (
    <div className="inline-block shadow-lg rounded-full">
      <svg width="64px" height="64px" viewBox="0 0 64 64">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <circle id="path-1" fill={backgroundColor || '#0BE881'} cx="32" cy="32" r="32" />
          <g id="facebook-copy" fillRule="nonzero">
            <path
              d="M46.5131215,22.3095983 C49.6768472,29.1600048 47.6656484,37.2989425 41.6800413,41.8681523 C38.2259094,44.614935 34.8197516,47.4099068 31.3656197,50.1566895 C30.9818273,50.4578718 30.5260738,50.710865 30.1063008,51 C29.9722566,50.9061942 29.8320078,50.8216676 29.6865279,50.7470069 C29.854437,49.4820411 29.9863657,48.1688863 30.1063008,46.8557314 C30.3221841,45.1691104 30.2382295,45.1209213 28.6310987,44.7835971 C21.2816701,43.1937521 16.0255438,36.678508 16.0000915,29.1269159 C15.9748234,21.5753238 21.187071,15.0246083 28.5256542,13.3849939 C35.8642373,11.7453795 43.3493959,15.4591919 46.5131215,22.3095983 Z M25.4168371,27.8451038 C24.2841613,27.8451038 23.3659463,28.7674362 23.3659463,29.9051908 C23.3659463,31.0429454 24.2841613,31.9652778 25.4168371,31.9652778 C26.5495128,31.9652778 27.4677279,31.0429454 27.4677279,29.9051908 C27.4677279,28.7674362 26.5495128,27.8451038 25.4168371,27.8451038 Z M32.2171592,27.8451038 C31.0844835,27.8451038 30.1662684,28.7674362 30.1662684,29.9051908 C30.1662684,31.0429454 31.0844835,31.9652778 32.2171592,31.9652778 C33.3498349,31.9652778 34.26805,31.0429454 34.26805,29.9051908 C34.26805,28.7674362 33.3498349,27.8451038 32.2171592,27.8451038 Z M38.5377408,27.8451038 C37.4050651,27.8451038 36.48685,28.7674362 36.48685,29.9051908 C36.48685,31.0429454 37.4050651,31.9652778 38.5377408,31.9652778 C39.6704165,31.9652778 40.5886316,31.0429454 40.5886316,29.9051908 C40.5886316,28.7674362 39.6704165,27.8451038 38.5377408,27.8451038 Z"
              id="Combined-Shape"
              fill={foregroundColor || '#FFFFFF'}
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default Bubble;
