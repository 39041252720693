/* ------ Module imports ------ */
import { useLocation } from 'react-router-dom';

function usePathname() {
  const location = useLocation();
  const { pathname } = location;

  return pathname[pathname.length - 1] === '/'
    ? pathname.substring(0, pathname.length - 1)
    : pathname;
}

export default usePathname;
