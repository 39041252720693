/* ------ Module imports ------ */
import React from 'react';

/* ------ Common imports ------ */
import Button from 'common/button';
import Colors from 'common/colors';
import PlainButton from 'common/plain-button';

function ChangeColors(props) {
  const {
    backgroundColor,
    foregroundColor,
    greeting,
    name,
    onBackgroundColorChanged,
    onCancel,
    onForegroundColorChanged,
    onSave,
    showActions,
    submitting,
  } = props;

  return (
    <>
      <div className="p-6">
        <Colors
          backgroundColor={backgroundColor}
          disabled={submitting}
          foregroundColor={foregroundColor}
          greeting={greeting}
          name={name}
          onBackgroundColorChanged={onBackgroundColorChanged}
          onForegroundColorChanged={onForegroundColorChanged}
        />
      </div>

      {showActions && (
        <div className="px-6 py-4 border-t border-gray-200">
          <Button
            loading={submitting}
            onClick={onSave}
          >
            Save changes
          </Button>

          <PlainButton
            className="ml-4"
            disabled={submitting}
            onClick={onCancel}
          >
            Cancel
          </PlainButton>
        </div>
      )}
    </>
  );
}

export default ChangeColors;
