/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ View import ------ */
import ApiKey from './api-key.view';

function ApiKeyContainer(props) {
  const {
    apiKey,
    onDeleted,
  } = props;

  const [deleting, setDeleting] = useState(false);

  return (
    <ApiKey
      apiKey={apiKey}
      deleting={deleting}
      onCancelDeleting={() => setDeleting(false)}
      onDeleted={onDeleted}
      onStartDeleting={() => setDeleting(true)}
    />
  );
}

export default ApiKeyContainer;
