/* ------ Module imports ------ */
import React, { useState } from 'react';

/* ------ Helpers ------ */
import api from 'helpers/api';
import useDidMount from 'helpers/hooks/use-did-mount';

/* ------ View import ------ */
import Users from './users.view';

function UsersContainer() {
  const [users, setUsers] = useState([]);
  const [view, setView] = useState('loading');

  async function fetchData() {
    let loadedUsers = null;
    try {
      const { data } = (await api.get('/connect_account_user')).data;
      loadedUsers = data;
    } catch (e) {
      // Silently ignore
    }

    if (loadedUsers) {
      setUsers(loadedUsers);
      setView('main');
    } else {
      setView('error');
    }
  }

  useDidMount(() => {
    fetchData();
  });

  return (
    <Users
      onUserDeleted={user => setUsers(users.filter(u => u.id !== user.id))}
      users={users}
      view={view}
    />
  );
}

export default UsersContainer;
